import { Element } from '@shared/interfaces/element.interface';
import React from 'react';

interface LoaderWrapperProps extends Element {
  className?: string;
}

export const LoaderWrapper: React.FC<LoaderWrapperProps> = ({ children, className = '' }) => {
  return (
    <div
      className={`w-full h-full flex items-center justify-center absolute z-50 top-0 left-0 bg-black/20 ${className}`}
    >
      {children}
    </div>
  );
};
