import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TextField } from '@shared/ui/TextField';
import { Button } from '@shared/ui/buttons/Button';

import { ErrorList } from '@shared/ui/ErrorList';
import config, { AppMode } from '@config';
import { Helmet } from 'react-helmet';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import { Loader } from '@shared/ui/loader/Loader';

interface PasswordProtectionProps {
  onSuccess: (token: string) => void;
}

const CLIENT_PASSWORD = 'ZWRvcG9tb2dhLThkZjE=';

export const PasswordProtection: React.FC<PasswordProtectionProps> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(true);

  const formMethods = useForm({
    defaultValues: {
      password: '',
    },
  });
  const [isError, setIsError] = useState(false);
  const { handleSubmit } = formMethods;

  const onSubmit = async (data: { password: string }) => {
    try {
      setIsError(false);

      const { password } = data;
      if (window.btoa(password) !== CLIENT_PASSWORD) {
        throw new Error('Invalid password');
      }

      onSuccess('success');
    } catch (err) {
      setIsError(true);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Helmet title={'Authorization Required'}>
        {config.mode === AppMode.Staging && <meta name="robots" content="noindex" />}
      </Helmet>
      <div className="flex justify-center">
        <div className="flex flex-col items-center justify-center min-h-[100vh] min-w-full md:min-w-[400px] max-w-[500px]">
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-center justify-center">
              <TextField
                id="password"
                name="password"
                type="password"
                label="Password"
                autoFocus
                className="w-full mb-10"
              />

              <Button as="button" type="submit">
                Go
              </Button>

              {isError && <ErrorList className="mt-5" errors={['errors.invalid-password']} />}
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
