import { Routes } from '@shared/enums';
import { PrivatePage } from '@shared/types/common.types';

export default [
  {
    url: Routes.CustomerCabinet,
    exact: false,
  },
  {
    url: Routes.Profile,
    exact: false,
  },
  {
    url: Routes.AuthUpdatePass,
    exact: false,
  },
  {
    url: Routes.SantaClausLetter,
    exact: false,
  },
  {
    url: Routes.AuthUpdatePhone,
    exact: false,
  },
] as PrivatePage[];
