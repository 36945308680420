import * as React from 'react';
import Select, { GroupBase, MenuPlacement, SingleValueProps } from 'react-select';
import clsx from 'clsx';

import { root } from './index.module.css';

import { Option } from '@shared/interfaces/option.interface';
import { Element } from '@shared/interfaces/element.interface';

interface SecondaryDropdownStyle {
  menu: object;
}

interface SecondaryDropdownProps extends Element {
  value?: string | null;
  defaultValue: Option | null;
  options?: Option[];
  className?: string;
  mode?: 'dark' | 'default';
  variant?: string;
  menuPlacement?: MenuPlacement;
  style?: SecondaryDropdownStyle;

  components?: {
    SingleValue?: React.ComponentType<SingleValueProps<Option, false, GroupBase<Option>>>;
  };

  onChange?: (newValue: Option | null) => void;
}

const modeColors = {
  default: {
    border: '#000000',
    text: '#000000',
    menuBackground: '#ffffff',
    optionColor: '#000000',
    optionFocusedBackground: 'rgba(0, 0, 0, 0.07)',
    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.2)',
  },
  dark: {
    border: '#ffffff',
    text: '#ffffff',
    menuBackground: '#3f3f3f',
    optionColor: '#ffffff',
    optionFocusedBackground: 'rgba(255, 255, 255, 0.14)',
    boxShadow: 'none',
  },
};

const getModeColors = ({ mode = 'default' } = {}) => {
  // @ts-ignore
  return modeColors[mode] || {};
};

const getSelectStyles = (style: SecondaryDropdownStyle) => ({
  control: (_: any, state: any) => {
    const selectProps = state.selectProps;
    const { variant } = selectProps;
    const isVariantDropdownMenu = variant === 'dropdown-menu';
    const colors = getModeColors(selectProps);

    return {
      display: 'flex',
      minWidth: 90,
      minHeight: 40,
      border: isVariantDropdownMenu ? 'none' : `2px solid ${colors.border}`,
      borderRadius: 21,
      // EXPLAIN: without "as const" get TS error
      textTransform: isVariantDropdownMenu ? 'none' : ('uppercase' as const),
      transition: 'background-color 150ms',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
      },
      '&:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.14)',
      },
    };
  },

  indicatorSeparator: () => ({
    display: 'none',
  }),

  dropdownIndicator: (_: any, state: any) => {
    const isMenuOpen = state.selectProps.menuIsOpen;
    const colors = getModeColors(state.selectProps);

    return {
      display: 'flex',
      marginRight: 10,
      color: `${colors.text}`,
      transition: 'transform 0.3s ease',
      transform: isMenuOpen && 'rotate(-180deg)',
    };
  },

  singleValue: (provided: any, state: any) => {
    const colors = getModeColors(state.selectProps);

    return {
      ...provided,
      overflow: 'none',
      textOverflow: 'none',
      color: `${colors.text}`,
    };
  },

  valueContainer: (provided: object) => {
    return {
      ...provided,

      paddingLeft: '16px',
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: 400,
    };
  },

  menu: (provided: any, state: any) => {
    const colors = getModeColors(state.selectProps);

    return {
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      padding: '16px 0',
      border: 'none',
      borderRadius: '8px',
      backgroundColor: colors.menuBackground,
      boxShadow: colors.boxShadow,
      ...style.menu,
    };
  },

  option: (provided: any, state: any) => {
    const colors = getModeColors(state.selectProps);

    const focusedStyles = {
      backgroundColor: colors.optionFocusedBackground,
    };

    return {
      ...provided,
      padding: '8px 16px',
      color: colors.optionColor,
      fontSize: '13px',
      lineHeight: '18px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      ...(state.isFocused ? focusedStyles : {}),
    };
  },
});

export const SecondaryDropdown: React.FC<SecondaryDropdownProps> = ({
  options = [],
  className,
  value = null,
  defaultValue = null,
  mode = 'default',
  variant = '',
  menuPlacement = 'bottom',
  style = {},
  components,
  onChange,
}) => {
  return (
    <div className={clsx(root, className)}>
      <Select
        value={options.find((option) => option.value === value) || defaultValue}
        options={options}
        isSearchable={false}
        // @ts-ignore

        styles={getSelectStyles(style)}
        // @ts-ignore
        mode={mode}
        components={components}
        variant={variant}
        onChange={onChange}
        menuPlacement={menuPlacement}
      />
    </div>
  );
};
