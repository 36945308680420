import * as React from 'react';
import { HeaderBase } from '../HeaderBase';
import { HeaderButtonGoToPreviousPage } from './HeaderButtonGoToPreviousPage';

export const HeaderAuth = () => {
  return (
    <HeaderBase>
      <HeaderButtonGoToPreviousPage />
    </HeaderBase>
  );
};
