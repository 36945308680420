import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const emptySplitApi = createApi({
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('authorization');

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      const passwordToken = localStorage.getItem('password-protection');
      if (passwordToken) {
        headers.set('X-Password-Token', passwordToken);
      }

      return headers;
    },
    headers: { 'Content-Type': 'application/json' },
  }),
  endpoints: () => ({}),
});
