import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';

import React from 'react';
import { useGetLanguageLink } from '@hooks/useGetLanguageLink';

export const Link = <TState extends unknown>(props: GatsbyLinkProps<TState> & { children?: React.ReactNode }) => {
  const getLanguageLink = useGetLanguageLink();

  // @ts-ignore
  return <GatsbyLink {...props} to={getLanguageLink(props.to)} />;
};
