import React from 'react';

import { Routes } from '@shared/enums';
import { LayoutAuth } from '@shared/layouts/LayoutAuth';
import { LayoutPrimary } from '@shared/layouts/LayoutPrimary';
import { LayoutSimple } from '@shared/layouts/LayoutSimple';
import { LayoutVolunteer } from '@shared/layouts/LayoutVolunteer';
import '../../i18n';

interface IPageLayout {
  element: React.ReactElement;
  props: any;
}

const layoutsConfig = {
  base: LayoutPrimary,
  auth: LayoutAuth,
  volunteer: LayoutVolunteer,
  simple: LayoutSimple,
};

function getLayoutType(location: any) {
  const { pathname } = location;
  if (pathname.includes(Routes.Auth)) {
    return 'auth';
  }
  if (pathname.includes(Routes.Receipts)) {
    return 'volunteer';
  }

  if (pathname.startsWith(Routes.Certificate)) {
    return 'simple';
  }

  return 'base';
}

function useComponentState({ location }: any) {
  const layoutType = getLayoutType(location);
  const layout = layoutsConfig[layoutType];

  return {
    layout,
    pathname: location.pathname,
  };
}

/**
 * NOTE: temporary while we don't fix SSR and SSG
 */
const isSSRorSSGPage = (pathname: string) =>
  [Routes.CustomerProfile, Routes.Legions].some((page) => pathname.includes(page));

/**
 * NOTE: temporary while we don't fix SSR and SSG.
 */
const isPageVisible = (pathname: string) => {
  return typeof window !== 'undefined' || isSSRorSSGPage(pathname);
};

const PageLayout: React.FC<IPageLayout> = ({ element, props }) => {
  const { layout: Layout, pathname } = useComponentState(props);

  if (!isPageVisible(pathname)) {
    return null;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Layout {...props}>{element}</Layout>
    </div>
  );
};

export default PageLayout;
