export enum AppMode {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

interface AppConfig {
  mode: AppMode;
  api: {
    url: string;
  };
  i18n: {
    languages: string[];
    preloadLanguages: string[];
  };
  passwordProtection: {
    enabled: boolean;
  };
  payments: {
    mastercardBonusEnabled: boolean;
  };
  features: {
    recipientGroupStatus: boolean;
    wog: boolean;
    atb: boolean;
    socar: boolean;
    easterCreateApplication: boolean;
    childrenProtectedOnline: boolean;
    easterPayApplication: boolean;
    userFeedback: boolean;
    userAvatar: boolean;
    santaClausLetter: boolean;
    santaClausOnlineLetter: boolean;
  };
  appItems: {
    defaultInProgressAtDays: number;
    defaultInProgressToDays: number;
    maxInProgressToDays: number;
  };
}

const config: AppConfig = {
  mode: ((process.env.GATSBY_MODE || process.env.NODE_ENV) as AppMode) || AppMode.Development,
  api: {
    url: process.env.GATSBY_API_URL || 'http://localhost:3001/api',
  },
  i18n: {
    languages: (process.env.GATSBY_LANGUAGES || 'en,uk').split(','),
    preloadLanguages: (process.env.GATSBY_PRELOAD_LANGUAGES || 'en,uk').split(','),
  },
  passwordProtection: {
    enabled: process.env.GATSBY_PASSWORD_PROTECTION_ENABLED === 'yes',
  },
  payments: {
    mastercardBonusEnabled: process.env.GATSBY_PAYMENT_FOZZY_MASTERCARD_BONUS_ENABLED === 'yes',
  },
  features: {
    childrenProtectedOnline: process.env.GATSBY_FEATURE_CHILD_PROTECTED_ENABLED === 'yes',
    recipientGroupStatus: process.env.GATSBY_FEATURE_RECIPIENT_GROUP_STATUS_ENABLED === 'yes',
    wog: process.env.GATSBY_WOG_ENABLED === 'yes',
    atb: process.env.GATSBY_FEATURE_ATB_ENABLED === 'yes',
    easterCreateApplication: process.env.GATSBY_FEATURE_EASTER_CREATE_APPLICATION_ENABLED === 'yes',
    easterPayApplication: process.env.GATSBY_FEATURE_EASTER_PAY_APPLICATION_ENABLED === 'yes',
    socar: process.env.GATSBY_FEATURE_EKTA_SOCAR_ENABLED === 'yes',
    userFeedback: process.env.GATSBY_FEATURE_USER_FEEDBACK === 'yes',
    userAvatar: process.env.GATSBY_FEATURE_USER_AVATAR === 'yes',
    santaClausLetter: process.env.GATSBY_FEATURE_SANTA_CLAUS_LETTER_ENABLED === 'yes',
    santaClausOnlineLetter: process.env.GATSBY_FEATURE_SANTA_CLAUS_ONLINE_LETTER_ENABLED === 'yes',
  },
  appItems: {
    defaultInProgressAtDays: 2,
    defaultInProgressToDays: 7,
    maxInProgressToDays: 14,
  },
};

export default config;
