import React from 'react';
import { VscClose } from 'react-icons/vsc';

import { useNavigate } from '@hooks/useNavigate';
import { useLocation } from 'react-use';

export const HeaderButtonGoToPreviousPage = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const handleGoToPrevPage = () => {
    if (state?.backTo) {
      navigate(state.backTo);
      return;
    }
    navigate(-1);
  };

  return (
    <button className="ml-auto" onClick={handleGoToPrevPage}>
      <VscClose size="34px" title="Go to previous page" />
    </button>
  );
};
