import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@shared/ui/buttons/Button';
import { GenericLink } from '@shared/ui/GenericLink';
import { useUser } from '@shared/hooks/useUser';

import navLinks from '@values/nav-links';

import { useGetLanguageLink } from '@hooks/useGetLanguageLink';
import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';

import { Text } from '@shared/ui/text';
import { LanguageSelect } from '@shared/ui/selects/LanguageSelect';
import { ProfileDropdown } from '@shared/ui/dropdowns/ProfileDropdown';

import { Navigation } from '../navigation';
import { HeaderBase } from './HeaderBase';
import { HelpButtons } from './buttons/HelpButtons';
import { ApplicationsSelect } from '@shared/ui/selects/ApplicationsSelect';

export const HeaderPrimary = () => {
  const { t } = useTranslation();

  const headerRef = useRef<HTMLDivElement>(null);
  const getLanguageLink = useGetLanguageLink();

  const isLgScreen = useMinWidthMediaQuery('lg');

  const [user] = useUser();

  const [isTopBarVisible, setTopBarVisible] = useState(false);

  return (
    <HeaderBase headerRef={headerRef}>
      <nav className="hidden xxl:block xl:mr-4 header-web-nav">
        <ul className="flex items-center gap-x-8 whitespace-nowrap">
          {navLinks.map(({ url, text }) => (
            <li key={url}>
              <GenericLink type="page" to={getLanguageLink(url)} className="text-sm hover:underline">
                {t(text)}
              </GenericLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex items-center ml-auto">
        {!isLgScreen && user?.isMember && !isTopBarVisible && <ApplicationsSelect className="sm:mr-2" />}
        {isLgScreen && <HelpButtons />}
        {!user?.isMember && (
          <div>
            <GenericLink type="page" to="/auth/sign-up" className="hidden sm:inline hover:underline mx-5">
              <Text size="sm">{t('sign-up')}</Text>
            </GenericLink>
            <Button as="link-in" size="small" to="/auth/sign-in">
              {t('sign-in')}
            </Button>
          </div>
        )}
        {isLgScreen && (
          <>
            <ProfileDropdown className="ml-4" />
            <LanguageSelect className="ml-4" />
          </>
        )}
        <Navigation
          anchor={headerRef}
          items={navLinks}
          className="ml-1 sm:ml-2 lg:hidden"
          onToggle={setTopBarVisible}
        />
      </div>
    </HeaderBase>
  );
};
