export enum ApplicantType {
  LegalEntity = 'legal-entity',
  HumanBeing = 'human-being',
}

export const CONTACT_REQUESTS = 'contact-requests' as const;

export type TabEnum = ApplicantType & '';

export enum PurchaseCategoryType {
  Fuel = 'fuel',
  Clothes = 'clothes',
}

export enum PurchaseStoreType {
  Default = 'default',
}

export enum ApplicationState {
  New = 'new',
  InProgress = 'in_progress',
  Processed = 'processed',
  Completed = 'completed',
}

export enum ApplicationTarget {
  Volunteer = 'volunteer',
  Customer = 'customer',
}

/**
 * Type user in the application
 *
 * `Volunteer` - user registered as `volunteer`
 *
 * `InNeed` - user registered as `customer`
 *
 * `Guest` - user didn't register but has some data & permissions to do more actions than
 *  anonymous users can do.
 */
export enum UserType {
  Volunteer = 'volunteer',
  InNeed = 'in_need',
  Guest = 'guest',
}

export enum HowToHelpType {
  HandToHand = 'hand',
  OnlineFood = 'online-food',
  OnlineMedicaments = 'online-medicaments',
  OnlineFuel = 'online-fuel',
  SantaClausLetter = 'santa-claus-letter',
  Easter = 'easter',
  ChildrenProtected = 'child-protection',
  ChildrenToy = 'baby-products',
}

export enum VolunteerMode {
  Online = 'online',
  Offline = 'offline',
}

export type OnlineHelp =
  | HowToHelpType.OnlineFood
  | HowToHelpType.OnlineFuel
  | HowToHelpType.OnlineMedicaments
  | HowToHelpType.SantaClausLetter;

export enum Routes {
  Home = '/',
  Cabinet = '/cabinet',
  VolunteerCabinet = '/cabinet/volunteer',
  CustomerCabinet = '/cabinet/customer',
  Auth = '/auth',
  AuthSignIn = '/auth/sign-in',
  AuthSignOut = '/auth/sign-out',
  AuthUpdatePass = '/auth/update-password',
  AuthUpdatePhone = '/auth/update-phone',
  AuthMergeUsers = '/auth/merge',
  SantaClausLetter = '/santa-claus/letter',
  Profile = '/profile',
  CustomerProfile = '/customer/profile/',
  Receipts = '/receipts',
  AuthSignUp = '/auth/sign-up',
  VolunteerSignUp = '/auth/sign-up/volunteer',
  CustomerSignUp = '/auth/sign-up/customer',
  Certificate = '/cert',
  OnlineHelp = '/cabinet/volunteer?category=online-food',
  HelpByHand = '/cabinet/volunteer?category=hand',
  QuestionsAnswers = '/questions-answers',
  SantaClausCustomer = '/santa-claus/customer',
  SantaClausVolunteer = '/santa-claus/volunteer',
  NotFoundPage = '/404',
  Donate = '/donate',
  Legions = '/legions',
  LegionInstruction = '/instructions/legion',
}

export enum SocialTypes {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Youtube = 'youtube',
}

export enum BannerMastercardVariant {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum Currency {
  Uah = 'UAH',
  Usd = 'USD',
  Eur = 'EUR',
  Pln = 'PLN',
}

export enum Language {
  Ukraine = 'uk',
}

export enum ModalNames {
  AddCustomerNeeds = 'add-customer-needs',
}

export enum Rating {
  Good = 5,
  Bad = -10,
}

export enum OauthProvider {
  IdGovUa = 'id-gov-ua',
}

export enum ApplicationView {
  Default = 'default',

  SantaClausLetter = 'santa-claus-letter',
}

export enum AdditionalStatusFilters {
  WithoutReceivedHelp = 'without-received-help',
  IdGovUaVerified = 'igu-verified',
}
