import * as React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const InstagramIcon: React.FC<IconProps> = ({ width = 36, height = 36, className = 'text-white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.28146 4.27824L4.27824 4.28146C3.2586 5.29878 2.6592 6.6423 2.33699 8.83686C2.00489 11.0988 2 13.997 2 17.9967C2 21.9964 2.00489 24.8947 2.337 27.1575C2.65922 29.3528 3.25879 30.6991 4.27985 31.7201L4.28147 31.7218C5.29878 32.7414 6.6423 33.3408 8.83686 33.663C11.0988 33.9951 13.997 34 17.9967 34C21.9964 34 24.8947 33.9951 27.1575 33.663C29.3529 33.3408 30.6991 32.7412 31.7201 31.7201C32.7415 30.6988 33.3409 29.3539 33.663 27.1599C33.9951 24.898 34 21.9998 34 17.9967C34 13.9937 33.9951 11.0955 33.663 8.8336C33.341 6.64018 32.7418 5.29538 31.7209 4.27411C30.6999 3.25577 29.3547 2.6577 27.1605 2.33627C24.8982 2.00487 21.9996 2 17.9967 2C13.9937 2 11.0955 2.00489 8.83451 2.33698C6.6413 2.6591 5.29908 3.2583 4.28146 4.27824ZM33.1344 2.85911C36 5.72475 36 10.1048 36 17.9967C36 25.8887 36 30.2687 33.1344 33.1344C30.2687 36 25.8821 36 17.9967 36C10.1113 36 5.72475 36 2.86564 33.1344C0 30.2687 0 25.8821 0 17.9967C0 10.1113 0 5.72475 2.86564 2.86564C5.72475 0 10.1048 0 17.9967 0C25.8887 0 30.2687 0 33.1344 2.85911Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9933 6.23804C26.3362 6.23804 25.6914 6.50341 25.2278 6.96937C24.7605 7.43334 24.4932 8.07916 24.4932 8.73861C24.4932 9.3955 24.7616 10.0404 25.2278 10.5064C25.6905 10.9704 26.3362 11.238 26.9933 11.238C27.6524 11.238 28.2949 10.9704 28.7608 10.5064C29.227 10.0404 29.4932 9.39436 29.4932 8.73861C29.4932 8.07916 29.227 7.43334 28.7608 6.96937C28.2969 6.50341 27.6524 6.23804 26.9933 6.23804Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 24C21.3137 24 24 21.3137 24 18C24 14.6863 21.3137 12 18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24ZM18 26C22.4183 26 26 22.4183 26 18C26 13.5817 22.4183 10 18 10C13.5817 10 10 13.5817 10 18C10 22.4183 13.5817 26 18 26Z"
        fill="currentColor"
      />
    </svg>
  );
};
