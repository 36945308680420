import { PurchaseCategoryType } from './enums';

export const PAGE_SIZE = 10;
export const INITIAL_PATHNAME = 'initial-pathname';
export const SIGN_UP = 'sign-up';
export const SIGN_IN = 'sign-in';

export const localeCurrencyMap = {
  uk: 'UAH',
  en: 'USD',
  de: 'EUR',
  pl: 'PLN',
  fr: 'EUR',
} as Record<string, string>;
