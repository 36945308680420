import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@shared/types/common.types';

import { RootState } from '../index';

interface AuthSlice {
  user: User | null;
  isInitialized: boolean;
  isFetching: boolean;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isInitialized: false,
    isFetching: false,
  } as AuthSlice,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload || null;
    },
    setIsFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetching = payload;
    },
    setIsInitialized: (state, { payload }: PayloadAction<boolean>) => {
      state.isInitialized = payload;
    },
  },
});

const getAuthState = (state: RootState) => state.auth;
export const selectAuthUser = createSelector(getAuthState, (auth) => auth.user);
export const selectIsFetching = createSelector(getAuthState, (auth) => auth.isFetching);
export const selectIsInitialized = createSelector(getAuthState, (auth) => auth.isInitialized);

export const authReducer = authSlice.reducer;
export const { setIsInitialized, setUser, setIsFetching } = authSlice.actions;
