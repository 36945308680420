import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationItem } from '@widgets/cabinet/applicationCard/types';

interface CustomerSlice {
  categoryId: string | number;
  tab: string;
  modal: string | null;
  applicationItems: ApplicationItem[];
  supermarket: string;
  pharmacy: string;
  gasStation: string;
  easterShop: string;
  childProtectedShop: string;
  childrenToysShop: string;
  supermarketRules: boolean;
  pharmacyRules: boolean;
  gasStationRules: boolean;
  easterRules: boolean;
  childrenToysRules: boolean;
  childProtectedRules: boolean;
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    categoryId: '',
    applicationItems: [],
    tab: 'create',
    modal: null,
    supermarket: '',
    pharmacy: '',
    gasStation: '',
    easterShop: '',
    childrenToysShop: '',
    childProtectedShop: '',
    supermarketRules: false,
    pharmacyRules: false,
    gasStationRules: false,
    easterRules: false,
    childrenToysRules: false,
    childProtectedRules: false,
  } as CustomerSlice,
  reducers: {
    changeCategoryId: (state, { payload }: PayloadAction<string | number>) => {
      state.categoryId = payload;
    },
    changeActiveTab: (state, { payload }: PayloadAction<string>) => {
      state.tab = payload;
    },
    setModal: (state, { payload }: PayloadAction<string | null>) => {
      state.modal = payload;
    },
    setSupermarket: (state, { payload }: PayloadAction<string>) => {
      state.supermarket = payload;
    },
    setPharmacy: (state, { payload }: PayloadAction<string>) => {
      state.pharmacy = payload;
    },
    setGasStation: (state, { payload }: PayloadAction<string>) => {
      state.gasStation = payload;
    },
    setEasterShop: (state, { payload }: PayloadAction<string>) => {
      state.easterShop = payload;
    },
    setChildProtectedShop: (state, { payload }: PayloadAction<string>) => {
      state.childProtectedShop = payload;
    },
    setChildToysShop: (state, { payload }: PayloadAction<string>) => {
      state.childrenToysShop = payload;
    },
    changeSupermarketRules: (state, { payload }: PayloadAction<boolean>) => {
      state.supermarketRules = payload;
    },
    changePharmacyRules: (state, { payload }: PayloadAction<boolean>) => {
      state.pharmacyRules = payload;
    },
    changeGasStationRules: (state, { payload }: PayloadAction<boolean>) => {
      state.gasStationRules = payload;
    },
    changeEasterRules: (state, { payload }: PayloadAction<boolean>) => {
      state.easterRules = payload;
    },
    changeChildProtectedRules: (state, { payload }: PayloadAction<boolean>) => {
      state.childProtectedRules = payload;
    },
    changeChildrenToysRules: (state, { payload }: PayloadAction<boolean>) => {
      state.childrenToysRules = payload;
    },
    addApplicationItems: (state, { payload }: PayloadAction<ApplicationItem[]>) => {
      state.applicationItems = payload;
    },
    createApplicationItem: (state, { payload }: PayloadAction<ApplicationItem>) => {
      const updatedItems = [...state.applicationItems.filter((item) => item.id !== payload.id), payload];
      state.applicationItems = updatedItems;
    },
    removeApplicationItem: (state, { payload }: PayloadAction<number>) => {
      const updatedItems = state.applicationItems.filter((item) => item.id !== payload);
      state.applicationItems = updatedItems;
    },
    clearApplicationItems: (state) => {
      state.applicationItems = [];
    },
    clearChooseStoreState: (state) => {
      state.modal = null;
      state.supermarket = '';
      state.pharmacy = '';
      state.gasStation = '';
      state.supermarketRules = false;
      state.pharmacyRules = false;
      state.gasStationRules = false;
    },
  },
});

export const customerReducer = customerSlice.reducer;

export const customerActions = {
  ...customerSlice.actions,
};
