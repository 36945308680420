import React from 'react';

import { Footer } from '@widgets/footer';
import { HeaderPrimary } from '@widgets/header/HeaderPrimary';

export const LayoutPrimary: React.FC<any> = ({ children }) => {
  return (
    <>
      <HeaderPrimary />
      <main className="main">{children}</main>
      <Footer />
    </>
  );
};
