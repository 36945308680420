export const formatAsNumber = (value: string | number) => Number(value).toFixed(2).replace('.', ',');

export const shortenUserName = (userName: string) => {
  const nameArray = userName.split(' ');
  if (nameArray.length <= 1) return userName;
  const shortSecondPart = nameArray[1].slice(0, 1).toUpperCase();
  const shortName = `${nameArray[0]} ${shortSecondPart}.`;
  return shortName;
};

// http://zakon1.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=55-2010-%EF
// http://search.ligazakon.ua/l_doc2.nsf/link1/KP100055.html
export function transliterate(ukrainianText: string, language: string = 'uk') {
  if (language === 'uk' || !ukrainianText) {
    return ukrainianText;
  }

  // Define english text.
  let englishText = '';
  for (let i = 0; i < ukrainianText.length; i++) {
    // Handle all chars.
    switch (ukrainianText[i]) {
      // TODO: fix it by updating locality_name in the users table!
      case 'Ы':
        englishText += 'Y';
        break;
      case 'ы':
        englishText += 'y';
        break;

      // Lowercase letters.
      case 'а':
        englishText += 'a';
        break;
      case 'б':
        englishText += 'b';
        break;
      case 'в':
        englishText += 'v';
        break;
      case 'г':
        if (i > 0) {
          if (ukrainianText[i - 1] === 'З' || ukrainianText[i - 1] === 'з') {
            englishText += 'gh';
          } else {
            englishText += 'h';
          }
        } else {
          englishText += 'h';
        }
        break;
      case 'ґ':
        englishText += 'g';
        break;
      case 'д':
        englishText += 'd';
        break;
      case 'е':
        englishText += 'e';
        break;
      case 'є':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'ye';
          } else {
            englishText += 'ie';
          }
        } else {
          englishText += 'ye';
        }
        break;
      case 'ж':
        englishText += 'zh';
        break;
      case 'з':
        englishText += 'z';
        break;
      case 'и':
        englishText += 'y';
        break;
      case 'і':
        englishText += 'i';
        break;
      case 'ї':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'yi';
          } else {
            englishText += 'i';
          }
        } else {
          englishText += 'yi';
        }
        break;
      case 'й':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'y';
          } else {
            englishText += 'i';
          }
        } else {
          englishText += 'y';
        }
        break;
      case 'к':
        englishText += 'k';
        break;
      case 'л':
        englishText += 'l';
        break;
      case 'м':
        englishText += 'm';
        break;
      case 'н':
        englishText += 'n';
        break;
      case 'о':
        englishText += 'o';
        break;
      case 'п':
        englishText += 'p';
        break;
      case 'р':
        englishText += 'r';
        break;
      case 'с':
        englishText += 's';
        break;
      case 'т':
        englishText += 't';
        break;
      case 'у':
        englishText += 'u';
        break;
      case 'ф':
        englishText += 'f';
        break;
      case 'х':
        englishText += 'kh';
        break;
      case 'ц':
        englishText += 'ts';
        break;
      case 'ч':
        englishText += 'ch';
        break;
      case 'ш':
        englishText += 'sh';
        break;
      case 'щ':
        englishText += 'shch';
        break;
      case 'ь':
        englishText += '';
        break;
      case 'ю':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'yu';
          } else {
            englishText += 'iu';
          }
        } else {
          englishText += 'yu';
        }
        break;
      case 'я':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'ya';
          } else {
            englishText += 'ia';
          }
        } else {
          englishText += 'ya';
        }
        break;

      // Uppercase letters.
      case 'А':
        englishText += 'A';
        break;
      case 'Б':
        englishText += 'B';
        break;
      case 'В':
        englishText += 'V';
        break;
      case 'Г':
        if (i > 0) {
          if (ukrainianText[i - 1] === 'З' || ukrainianText[i - 1] === 'з') {
            englishText += 'Gh';
          } else {
            englishText += 'H';
          }
        } else {
          englishText += 'H';
        }
        break;
      case 'Ґ':
        englishText += 'G';
        break;
      case 'Д':
        englishText += 'D';
        break;
      case 'Е':
        englishText += 'E';
        break;
      case 'Є':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'Ye';
          } else {
            englishText += 'Ie';
          }
        } else {
          englishText += 'Ye';
        }
        break;
      case 'Ж':
        englishText += 'Zh';
        break;
      case 'З':
        englishText += 'Z';
        break;
      case 'И':
        englishText += 'Y';
        break;
      case 'І':
        englishText += 'I';
        break;
      case 'Ї':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'Yi';
          } else {
            englishText += 'I';
          }
        } else {
          englishText += 'Yi';
        }
        break;
      case 'Й':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'Y';
          } else {
            englishText += 'I';
          }
        } else {
          englishText += 'Y';
        }
        break;
      case 'К':
        englishText += 'K';
        break;
      case 'Л':
        englishText += 'L';
        break;
      case 'М':
        englishText += 'M';
        break;
      case 'Н':
        englishText += 'N';
        break;
      case 'О':
        englishText += 'O';
        break;
      case 'П':
        englishText += 'P';
        break;
      case 'Р':
        englishText += 'R';
        break;
      case 'С':
        englishText += 'S';
        break;
      case 'Т':
        englishText += 'T';
        break;
      case 'У':
        englishText += 'U';
        break;
      case 'Ф':
        englishText += 'F';
        break;
      case 'Х':
        englishText += 'Kh';
        break;
      case 'Ц':
        englishText += 'Ts';
        break;
      case 'Ч':
        englishText += 'Ch';
        break;
      case 'Ш':
        englishText += 'Sh';
        break;
      case 'Щ':
        englishText += 'Shch';
        break;
      case 'Ь':
        englishText += '';
        break;
      case 'Ю':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'Yu';
          } else {
            englishText += 'Iu';
          }
        } else {
          englishText += 'Yu';
        }
        break;
      case 'Я':
        if (i > 0) {
          if (ukrainianText[i - 1] === ' ' || ukrainianText[i - 1] === '\n') {
            englishText += 'Ya';
          } else {
            englishText += 'Ia';
          }
        } else {
          englishText += 'Ya';
        }
        break;

      // Apostrophe.
      case "'":
        // Hide apostrophe.
        englishText += '';
        break;

      // Other chars.
      default:
        // Show other chars as is.
        englishText += ukrainianText[i];
        break;
    }
  }

  // Return english text.
  return englishText;
}

export function prepareLocalityName(locality: string) {
  // Replace common apostrophe "'" (0x27) with the ukrainian apostrophe "’" (0x2019)
  return locality.replace(/'/, '’');
}
