import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import privatePages from '@values/private-pages';
import { AuthProvider } from '@shared/providers/AuthProvider';
import { PasswordProtectionProvider } from './providers/PasswordProtectionProvider';

import { store } from '../store';
import { LanguageProvider } from './providers/LanguageProvider';

import 'react-toastify/dist/ReactToastify.css';

export const WrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <Provider store={store}>
      <LanguageProvider>
        <PasswordProtectionProvider>
          <AuthProvider privatePages={privatePages}>{element}</AuthProvider>
        </PasswordProtectionProvider>
        <ToastContainer closeButton={false} limit={3} />
      </LanguageProvider>
    </Provider>
  );
};
