import config from '@config';

/**
 * Get pathname with search string and hash components
 */
export function getFullPathname() {
  try {
    return window.location.pathname + window.location.search + window.location.hash;
  } catch (err) {
    return '/';
  }
}

/**
 * Get path with language
 */
export function getPathWithLanguage(path: string, language: string) {
  try {
    const pathname = path.startsWith('/') ? path.slice(1) : path;
    const parts = pathname.split('/');

    const pathLanguage = config.i18n.languages.includes(parts[0]) ? parts[0] : null;

    if (pathLanguage) {
      parts[0] = language;
    } else {
      parts.unshift(language);
    }

    return `/${parts.join('/')}`;
  } catch (err) {
    return `/${language}`;
  }
}

export function extractPathLanguage(path: string) {
  try {
    const pathname = path.startsWith('/') ? path.slice(1) : path;
    const parts = pathname.split('/');

    return config.i18n.languages.includes(parts[0]) ? parts[0] : null;
  } catch (err) {
    return null;
  }
}

/**
 * Remove language from path
 */
export function stripPathLanguage(path: string) {
  try {
    const pathname = path.startsWith('/') ? path.slice(1) : path;
    const parts = pathname.split('/');

    const firstPartIsLanguage = typeof parts[0] === 'string' && config.i18n.languages.includes(parts[0]);
    if (firstPartIsLanguage) {
      return `/${parts.slice(1).join('/')}`;
    }

    return path;
  } catch (err) {
    return path;
  }
}

export function getSearchParams() {
  try {
    return new URLSearchParams(window.location.search);
  } catch (err) {
    return null;
  }
}

export const setSearchParam = (name: string, value: string) => {
  try {
    const currentParams = getSearchParams();
    if (!currentParams) {
      return;
    }

    currentParams.set(name, value);

    window.history.replaceState(null, '', `?${currentParams.toString()}`);
  } catch (err) {}
};

export function updateSearchParams(updater: (value: URLSearchParams) => URLSearchParams | null) {
  try {
    const url = new URL(window.location.toString());

    const searchParams = getSearchParams();
    url.search = searchParams ? updater(searchParams)?.toString() || '' : '';

    window.history.pushState(null, '', url.toString());
  } catch (err) {
    console.log(err, 'err');
  }
}

export function redirect(url: string, options: { rewrite?: boolean } = {}) {
  const { rewrite = false } = options;

  if (rewrite) {
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
}
