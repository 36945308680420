import * as React from 'react';

export function useClientDimensions(ref: React.RefObject<HTMLElement | null>) {
  const [clientDimensions, setClientDimensions] = React.useState({ height: 0, width: 0 });

  function updateClientDimensions() {
    if (!ref.current) return;

    const height = ref.current.clientHeight;
    const width = ref.current.clientWidth;

    setClientDimensions({ height, width });
  }

  React.useEffect(() => {
    updateClientDimensions();
  }, [ref]);

  React.useEffect(() => {
    function onResize() {
      updateClientDimensions();
    }

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [ref]);

  return clientDimensions;
}
