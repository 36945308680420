import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { load } from 'redux-localstorage-simple';

import { AdditionalStatusFilters, ApplicantType, HowToHelpType, VolunteerMode } from '@shared/enums';
import { isEmptyObject } from '@shared/utils';
import { isBrowser } from '@shared/utils/dom';
import { getSearchParams } from '@shared/utils/path';

export interface VolunteerFilters {
  category: HowToHelpType;
  applicantType: ApplicantType | null;
  regionId: number | null;
  localityId: number | null;
  categoryIds: number[];
  categoryItemIds: number[];
  applicantCategoryIds: number[];
  additional: string[];
  shop: string | null;
}

interface VolunteerPagination {
  page: number;
  perPage: number;
}

interface VolunteerSlice {
  mode: VolunteerMode;
  filters: VolunteerFilters;
  pagination: VolunteerPagination;
  tab: string;
}

export const volunteerSlice = createSlice({
  name: 'volunteer',
  initialState: () => {
    const searchParams = getSearchParams();

    const persistedState = isBrowser() ? load({ states: ['volunteer'], namespace: 'edopomoga' }) : {};

    const defaultState = {
      mode: 'online',
      filters: {
        category: searchParams?.get('category') || HowToHelpType.OnlineMedicaments,
        applicantType: ApplicantType.HumanBeing,
        regionId: null,
        localityId: null,
        categoryIds: [],
        categoryItemIds: [],
        applicantCategoryIds: [],
        additional: [],
        shop: null,
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      tab: 'new',
    };

    return (isEmptyObject(persistedState) ? defaultState : persistedState) as VolunteerSlice;
  },
  // TODO: refactor reset filters
  reducers: {
    setMode: (state, { payload }: PayloadAction<VolunteerMode>) => {
      state.mode = payload;
    },

    changeActiveTabway: (state, { payload }: PayloadAction<{ tab: string }>) => {
      state.tab = payload.tab;

      // TODO: pass as argument
      // state.filters.way = HowToHelpType.OnlineFood;
    },
    changeActiveTab: (state, { payload }: PayloadAction<string>) => {
      state.tab = payload;

      state.filters.applicantType = ApplicantType.HumanBeing;
      state.filters.regionId = null;
      state.filters.localityId = null;
      state.filters.shop = null;
      state.filters.categoryIds = [];
      state.filters.categoryItemIds = [];
      state.filters.applicantCategoryIds = [];
      state.filters.additional = [];
    },

    setApplicantTypeFilter: (state, { payload }: PayloadAction<ApplicantType | null>) => {
      state.filters.applicantType = payload;

      state.filters.categoryIds = [];
      state.filters.categoryItemIds = [];
      state.filters.applicantCategoryIds = [];
    },

    setCategoryFilter: (state, { payload }: PayloadAction<HowToHelpType>) => {
      state.filters.category = payload;

      state.filters.categoryIds = [];
      state.filters.categoryItemIds = [];
      state.filters.applicantCategoryIds = [];
      state.filters.shop = null;
      if (payload === HowToHelpType.HandToHand) {
        state.filters.applicantType = null;
      } else {
        state.filters.applicantType = ApplicantType.HumanBeing;
      }
    },
    setCategoryIdsFilter: (state, { payload }: PayloadAction<number[]>) => {
      state.filters.categoryIds = payload;

      state.filters.categoryItemIds = [];
    },
    setCategoryItemsIdsFilter: (state, { payload }: PayloadAction<number[]>) => {
      state.filters.categoryItemIds = payload;
    },
    setApplicantCategoryIdsFilter: (state, { payload }: PayloadAction<number[]>) => {
      state.filters.applicantCategoryIds = payload;
    },
    setRegionIdFilter: (state, { payload }: PayloadAction<number | null>) => {
      // console.log('setRegionIdFilter', payload);
      state.filters.regionId = payload;

      state.filters.localityId = null;
    },
    setLocalityIdFilter: (state, { payload }: PayloadAction<number | null>) => {
      // console.log('setLocalityIdFilter', payload);
      state.filters.localityId = payload;
    },
    setAdditionalFilter: (state, { payload }: PayloadAction<AdditionalStatusFilters>) => {
      const additional = (state.filters.additional || []).slice();

      const index = additional.findIndex((item) => item === payload);
      if (index >= 0) {
        additional.splice(index, 1);
      } else {
        additional.push(payload);
      }

      state.filters.additional = additional;
    },
    setShopFilter: (state, { payload }: PayloadAction<string | null>) => {
      state.filters.shop = payload;
    },
    setPagination: (state, { payload }: PayloadAction<{ page: number; perPage: number }>) => {
      state.pagination.page = payload.page;
      state.pagination.perPage = payload.perPage;
    },
    resetPagination: (state) => {
      state.pagination.page = 1;
      state.pagination.perPage = 10;
    },
    resetFilters: (state) => {
      state.filters = {
        category: HowToHelpType.OnlineMedicaments,
        applicantType: ApplicantType.HumanBeing,
        regionId: null,
        localityId: null,
        categoryIds: [],
        categoryItemIds: [],
        applicantCategoryIds: [],
        additional: [],
        shop: null,
      };

      state.tab = 'new';

      state.pagination.page = 1;
      state.pagination.perPage = 10;
    },
  },
});

export const volunteerReducer = volunteerSlice.reducer;

export const volunteerActions = {
  ...volunteerSlice.actions,
};
