import { HeaderAuth } from '@widgets/header/headerAuth/HeaderAuth';
import React from 'react';

const useComponentState = (props: any) => {
  return {
    ...props,
  };
};

export const LayoutAuth: React.FC<any> = (props) => {
  const { children } = useComponentState(props);

  return (
    <>
      <HeaderAuth />
      <main className="main">{children}</main>
    </>
  );
};
