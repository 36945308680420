export function downloadFile(content: Blob, filename: string) {
  const url = URL.createObjectURL(content);
  const link = document.createElement('a');

  link.download = filename;
  link.href = url;

  document.body.appendChild(link);

  link.click();

  URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
}

export function createFullObjectFile(content: Blob, filename: string) {
  const url = URL.createObjectURL(content);
  const object = document.createElement('object');

  object.innerHTML = `
    <div>
      <p>
        Your web browser doesn't have a PDF plugin.

        Instead you can <a href="${url}" class="text-shamrock underline">click here</a> to download the PDF file
      </p>
    </div>
  `;

  object.data = url;
  object.type = 'application/pdf';
  object.width = '100%';
  object.height = '100%';

  object.style.position = 'absolute';
  object.style.width = '100%';
  object.style.height = '100%';
  object.style.top = '0';
  object.style.left = '0px';
  object.style.zIndex = '9999';

  const div = object.querySelector('div');

  if (div) {
    div.style.backgroundColor = '#f5f5fa';

    div.style.width = '100%';
    div.style.height = '100%';
    div.style.display = 'flex';
    div.style.justifyContent = 'center';
    div.style.alignItems = 'center';
    div.style.padding = '20px 30px';
    div.style.textAlign = 'center';
  }

  document.body.appendChild(object);
}

export const isBrowser = () => typeof window !== 'undefined';

function buildFormData(formData: FormData, data: any, parentKey?: string) {
  const isNestedStep = data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof Blob);

  if (isNestedStep) {
    for (const key of Object.keys(data)) {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    }
  } else if (parentKey && data != null && data != undefined) {
    formData.append(parentKey, data);
  }

  return formData;
}

export const toFormData = (obj: object) => buildFormData(new FormData(), obj);
