import { useCallback } from 'react';

import { getPathWithLanguage } from '@shared/utils/path';
import { useLanguage } from './useLanguage';

export function useGetLanguageLink() {
  const language = useLanguage();

  return useCallback((path: string) => getPathWithLanguage(path, language), [language]);
}
