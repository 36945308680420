import React from 'react';

import config, { AppMode } from '@config';

import useLocalStorage from '@hooks/useLocalStorage';
import { PasswordProtection } from '@widgets/password-protection';

interface PasswordProtectionProviderProps {
  children: React.ReactElement;
}

export const PasswordProtectionProvider: React.FC<PasswordProtectionProviderProps> = ({ children }) => {
  const [passwordProtection, setPasswordProtection] = useLocalStorage('password-protection');

  if (config.mode === AppMode.Production) {
    return children;
  }

  if (!config.passwordProtection.enabled || passwordProtection) {
    return children;
  }

  return <PasswordProtection onSuccess={(token) => setPasswordProtection(token)} />;
};
