import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useLanguage() {
  const { i18n } = useTranslation();

  return useMemo(() => {
    const fallbackLng = typeof window !== 'undefined' ? window.localStorage.getItem('i18nextLng') : null;

    return i18n.resolvedLanguage || fallbackLng || 'en';
  }, [i18n.resolvedLanguage]);
}
