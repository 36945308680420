import {
  ContactRequestStatus,
  useGetContactRequestQuery,
  useGetMySendContactRequestQuery,
} from '@store/api/contact-request.api';

export const useContactRequestData = () => {
  const { data: myContactRequests } = useGetContactRequestQuery();
  const { data: mySendContactRequests } = useGetMySendContactRequestQuery();

  const myContactCounter =
    myContactRequests?.items.filter(
      (cr) =>
        ![ContactRequestStatus.Approved, ContactRequestStatus.NotApproved].includes(cr.status as ContactRequestStatus),
    )?.length || 0;

  const mySendContactCounter =
    mySendContactRequests?.items.filter((cr) => {
      return (
        [ContactRequestStatus.Approved, ContactRequestStatus.NotApproved].includes(cr.status as ContactRequestStatus) &&
        !cr.isDeliveredToVolunteer
      );
    })?.length || 0;

  return {
    count: mySendContactCounter + myContactCounter,
    mySendContactCounter,
    myContactCounter,
    myContactRequests,
    mySendContactRequests,
  };
};
