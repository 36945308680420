import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

import config from '@config';

/**
 * TODO: cache translations
 */

i18next
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)

  .init({
    fallbackLng: ['en', 'uk'],

    debug: false,
    load: 'currentOnly',
    supportedLngs: config.i18n.languages,
    preload: config.i18n.preloadLanguages,

    detection: {
      order: ['path', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },

    interpolation: {
      escapeValue: false, // not needed for react
    },

    react: {
      useSuspense: false,
    },

    backend: {
      backends: [HttpBackend, HttpBackend],
      backendOptions: [
        {
          loadPath: `${config.api.url}/translations/{{lng}}.json`,
          parse: function (data: string) {
            return JSON.parse(data).messages;
          },
        },
        /**
         * Fallback languages
         */
        {
          loadPath: `/locales/{{lng}}.json`,
        },
      ],
    },
  });
