import * as React from 'react';
import clsx from 'clsx';

import * as burgerStyles from './index.module.css';

interface BurgerProps {
  ariaText: string;
  active?: boolean;
  onClick?: () => void;
}

// TODO: pass size of burger
export const BurgerButton: React.FC<BurgerProps> = ({ active = false, ariaText = '', onClick }) => {
  return (
    <button className={clsx(burgerStyles.root, active && burgerStyles.active)} type="button" onClick={onClick}>
      <span className={burgerStyles.box}>
        <span className={burgerStyles.inner}>
          <span className="visually-hidden">{ariaText}</span>
        </span>
      </span>
    </button>
  );
};
