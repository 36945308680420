import { InputCloseIcon } from '@shared/icons/InputCloseIcon';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

interface TextFieldProps {
  id: string;
  type?: string;
  name?: string;
  label: string;
  placeholder?: string;
  className?: string;
  helperText?: string | React.ReactElement;
  clearable?: boolean;
  autoFocus?: boolean;
}

export const TextField = (props: TextFieldProps) => {
  const {
    id,
    type = 'text',
    name = id,
    label,
    placeholder = label,
    className = '',
    helperText = '',
    clearable = false,
    autoFocus,
  } = props;

  const [inputType, setInputType] = useState(type);
  const [passShown, setPassShown] = useState(false);
  const isInitialTypePassword = type === 'password';

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const handleClear = () => {
    setValue(name, '');
  };
  const handleTogglePassVisibility = () => {
    const inputNewType = passShown ? 'password' : 'text';

    setInputType(inputNewType);
    setPassShown((state) => !state);
  };

  const errorText = errors[name]?.message;
  const { t } = useTranslation();

  return (
    <div className={clsx('relative', className)}>
      <input
        {...register(name)}
        type={inputType}
        id={id}
        placeholder={t(placeholder)}
        className={clsx(
          'peer w-full h-[50px] pl-4 pt-5 pb-2 outline-none bg-transparent text-base text-black transition-all border-b-[1px] border-b-solid placeholder:text-black-50 hover:bg-black-7 focus:bg-black-7 placeholder-shown:py-3',
          isInitialTypePassword || clearable ? 'pr-10' : 'pr-4',
          errorText ? 'border-b-carnation' : 'border-b-black',
        )}
        autoFocus={autoFocus}
      />
      <label
        htmlFor={id}
        className="absolute left-0 top-[1px] px-4 pointer-events-none w-full transition-all text-xs text-black-50 peer-placeholder-shown:text-transparent peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-base"
      >
        {t(label)}
      </label>
      {clearable && (
        <button className="absolute right-0 top-0 flex justify-center items-center px-[8px] py-3" onClick={handleClear}>
          <InputCloseIcon />
        </button>
      )}
      {isInitialTypePassword && (
        <button
          type="button"
          onClick={handleTogglePassVisibility}
          className="absolute right-1 top-0 h-full flex justify-center items-center px-[6px] py-3"
        >
          {passShown ? (
            <MdOutlineVisibility size="22" className="text-gray" />
          ) : (
            <MdOutlineVisibilityOff size="22" className="text-gray" />
          )}
        </button>
      )}
      {errorText && (
        <span className="absolute left-0 top-full mt-[5px] px-4 text-xxs text-carnation">{t(errorText)}</span>
      )}
      {!errorText && helperText && <span className="px-4 py-[5px] text-xxs text-black-50">{helperText}</span>}
    </div>
  );
};
