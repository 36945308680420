import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { BurgerButton } from '@shared/buttons/burger';

import { useUser } from '@shared/hooks/useUser';
import { ProfileDropdown } from '@shared/ui/dropdowns/ProfileDropdown';
import { LinkOption } from '@shared/interfaces/link-option.interface';
import { Element } from '@shared/interfaces/element.interface';

import { useToggle } from '@hooks/useToggle';
import { useClientDimensions } from '@hooks/useClientDimensions';
import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { useGetLanguageLink } from '@hooks/useGetLanguageLink';

import { HelpButtons } from './header/buttons/HelpButtons';
import { useWindowDimensions } from '@hooks/useWindowDimensions';
import { GenericLink } from '@shared/ui/GenericLink';
import { LanguageSelect } from '@shared/ui/selects/LanguageSelect';
import { Routes } from '@shared/enums';
import { useContactRequestData } from './profile/useContactRequestData';

interface NavigationProps extends Element {
  anchor: React.RefObject<HTMLElement | null>;
  items: LinkOption[];
  onToggle?: (active: boolean) => void;
}

const additionalNavItems = [
  {
    url: Routes.Profile,
    text: 'my-profile',
    withBadge: true,
  },
];

export const Navigation: React.FC<NavigationProps> = ({ anchor, items, onToggle, className }) => {
  const { t } = useTranslation();
  const [user] = useUser();

  const [navigationActive, toggleNavigationActive] = useToggle({ defaultValue: false });
  const dimensions = useClientDimensions(anchor);
  const windowDimensions = useWindowDimensions();
  const isXLScreen = useMinWidthMediaQuery('xl');
  const getLanguageLink = useGetLanguageLink();

  const menuStyles = { top: `${dimensions.height}px`, height: `${windowDimensions.height - dimensions.height}px` };

  const { count } = useContactRequestData();

  // TODO: make test on mobile devices
  // TODO: refactor
  // https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
  React.useEffect(() => {
    if (navigationActive) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = '';
    }

    if (!onToggle) return;
    onToggle(navigationActive);
  }, [navigationActive]);

  React.useEffect(() => {
    if (isXLScreen && navigationActive) {
      toggleNavigationActive();
    }
  }, [isXLScreen, navigationActive]);

  return (
    <div className={clsx('z-40', className, { 'ml-auto': navigationActive })}>
      <div className="relative">
        {count !== 0 && (
          <div
            className="absolute w-4 h-4 text-white flex items-center justify-center bg-blue rounded-full"
            style={{ top: -4, right: -8, zIndex: 10 }}
          />
        )}
        <BurgerButton ariaText={t('open-navigation-menu')} active={navigationActive} onClick={toggleNavigationActive} />
      </div>

      <div
        className={clsx(
          'absolute left-0 w-full bg-white shadow-top-hawkes-blue translate-x-reverse-full transition-transform',
          navigationActive && 'translate-x-0',
        )}
        style={menuStyles}
      >
        <div className="flex flex-col h-full pb-5 overflow-y-auto">
          <div className="flex flex-col items-center gap-y-6 text-center mt-3.125 mb-6">
            {items.map(({ text, url }) => (
              <GenericLink
                key={url}
                type="page"
                to={getLanguageLink(url)}
                className="text-sm hover:underline"
                onClick={toggleNavigationActive}
              >
                {t(text)}
              </GenericLink>
            ))}
            {additionalNavItems.length > 0 &&
              additionalNavItems.map(({ text, url, withBadge }) => (
                <GenericLink
                  key={url}
                  type="page"
                  to={getLanguageLink(url)}
                  className="text-sm hover:underline"
                  onClick={toggleNavigationActive}
                >
                  {t(text)}
                  {withBadge && count !== 0 && <span className="inline-block w-2 h-2 ml-2 bg-blue rounded-full" />}
                </GenericLink>
              ))}
          </div>

          <div className="flex flex-col items-center mt-10 mb-5">
            <HelpButtons onClick={toggleNavigationActive} />
          </div>

          <div className="flex flex-col items-center text-center mt-auto gap-y-5">
            {!user?.isMember && (
              <GenericLink
                onClick={toggleNavigationActive}
                type="page"
                to={getLanguageLink('/auth/sign-up')}
                className="text-sm hover:underline"
              >
                {t('sign-up')}
              </GenericLink>
            )}

            <ProfileDropdown onSelect={toggleNavigationActive} menuPlacement="top" />
            <LanguageSelect menuPlacement="top" onLanguageChange={toggleNavigationActive} />
          </div>
        </div>
      </div>
    </div>
  );
};
