import * as React from 'react';

export default function useMediaQuery(query: string): boolean {
  const checkMatches = (query: string): boolean => {
    if (typeof window === 'undefined') {
      return false;
    }

    return window.matchMedia(query).matches;
  };

  const [matches, setMatches] = React.useState(checkMatches(query));

  function handleChange() {
    setMatches(checkMatches(query));
  }

  React.useEffect(() => {
    const matchMedia = window.matchMedia(query);

    /**
     * Triggered at the first client-side load and if query changes
     */
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
  }, [query]);

  return matches;
}
