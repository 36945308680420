import * as React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const FacebookIcon: React.FC<IconProps> = ({ width = 36, height = 36, className = 'text-white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.57194 3.57355L3.57355 3.57194C4.79714 2.34556 6.37314 1.68775 8.68919 1.34759C11.0392 1.00245 14.0222 1 17.9967 1C21.9712 1 24.9545 1.00243 27.3055 1.34683C29.6222 1.68621 31.2004 2.34254 32.4273 3.56622C33.6543 4.79331 34.3122 6.37149 34.6524 8.68833C34.9976 11.0391 35 14.0222 35 17.9967C35 21.9712 34.9976 24.9543 34.6524 27.3051C34.3122 29.622 33.6543 31.2002 32.4273 32.4273C31.2003 33.6542 29.6207 34.3122 27.3027 34.6524C24.9511 34.9976 21.9679 35 17.9967 35C14.0255 35 11.0424 34.9976 8.69159 34.6524C6.37446 34.3122 4.79699 33.6543 3.57355 32.4281L3.57275 32.4273C2.34581 31.2003 1.68782 29.6207 1.3476 27.3027C1.00244 24.9511 1 21.9679 1 17.9967C1 14.0255 1.00244 11.0424 1.3476 8.69159C1.68781 6.37446 2.34571 4.79699 3.57194 3.57355Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M15.9053 28V19.502H13V16.125H15.9053V13.4643C15.9053 10.5734 17.7083 9 20.3409 9C21.6023 9 22.6856 9.09277 23 9.13359V12.1543H21.1742C19.7424 12.1543 19.4659 12.8223 19.4659 13.7982V16.125H22.697L22.2538 19.502H19.4659V28"
        fill="currentColor"
      />
    </svg>
  );
};
