import * as React from 'react';

import { LogoIcon } from '@shared/icons/logo';
import { TryzubIcon } from '@shared/icons/tryzub';
import { Text } from '@shared/ui/text';
import { GenericLink } from '@shared/ui/GenericLink';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '@hooks/useLanguage';
import { useWindowScroll } from 'react-use';
import clsx from 'clsx';

interface HeaderBaseProps {
  headerRef?: React.RefObject<HTMLDivElement> | null;
  children?: React.ReactNode;
}

function useComponentState(props: HeaderBaseProps) {
  const language = useLanguage();
  const { y: windowScrollY } = useWindowScroll();
  const hasWindowScroll = windowScrollY > 0;
  const headerStyles = clsx('sticky left-0 top-0 right-0 py-2 z-30 lg:py-4 bg-white transition-shadow', {
    'shadow-header': hasWindowScroll,
  });

  return {
    ...props,
    language,
    headerStyles,
  };
}

export const HeaderBase: React.FC<HeaderBaseProps> = (props) => {
  const { headerRef = null, children, headerStyles, language } = useComponentState(props);
  const { t } = useTranslation();

  return (
    <header ref={headerRef} className={headerStyles}>
      <div className="container_2xl flex items-center">
        <div className="flex items-center mr-1 xl:mr-14">
          <GenericLink className="flex items-center mr-auto" type="page" to="/">
            <TryzubIcon className="shrink-0 w-[2rem] h-[2rem] mr-2 md:w-[3rem] md:h-[3rem]" />
            <LogoIcon className="shrink-0 w-[2rem] h-[2rem] mr-2 md:w-[3rem] md:h-[3rem] md:mr-4" />
            <div className="flex flex-col justify-between">
              <Text weight="bold" size="sm" className=" md:text-base">
                {t('dopomoga')}
              </Text>
              <div className="text-[9px] md:text-[12px] leading-none text-black opacity-50">{t('logo-volunteer')}</div>
            </div>
          </GenericLink>
        </div>
        {children}
      </div>
    </header>
  );
};
