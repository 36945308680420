import { UserType } from '@shared/enums';
import { ExtendedUser, User } from '@shared/types/common.types';

export const toExtendedUser: (user: User) => ExtendedUser = (user) => {
  return {
    ...user,
    isGuest: user.type === UserType.Guest,
    isMember: [UserType.InNeed, UserType.Volunteer].includes(user.type) || user.type === null,
  };
};
