export default {
  bronze: {
    title: {
      uk: 'Долучайся до легіону єДопоможців',
      en: 'Join the legion of eDopomoga',
    },
    description: {
      uk: "Підтримай українські сім'ї, які потребують допомоги.",
      en: 'Support Ukrainian families in need.',
    },
    ogImage: {
      uk: 'https://social.edopomoga.gov.ua/images/metadata/badges/bronze.jpg',
      en: 'https://social.edopomoga.gov.ua/images/metadata/badges/bronze.jpg',
    },
  },

  silver: {
    title: {
      uk: 'Долучайся до легіону єДопоможців',
      en: 'Join the legion of eDopomoga',
    },
    description: {
      uk: "Підтримай українські сім'ї, які потребують допомоги.",
      en: 'Support Ukrainian families in need.',
    },
    ogImage: {
      uk: 'https://social.edopomoga.gov.ua/images/metadata/badges/silver.jpg',
      en: 'https://social.edopomoga.gov.ua/images/metadata/badges/silver.jpg',
    },
  },

  gold: {
    title: {
      uk: 'Долучайся до легіону єДопоможців',
      en: 'Join the legion of eDopomoga',
    },
    description: {
      uk: "Підтримай українські сім'ї, які потребують допомоги.",
      en: 'Support Ukrainian families in need.',
    },
    ogImage: {
      uk: 'https://social.edopomoga.gov.ua/images/metadata/badges/gold.jpg',
      en: 'https://social.edopomoga.gov.ua/images/metadata/badges/gold.jpg',
    },
  },

  diamant: {
    title: {
      uk: 'Долучайся до легіону єДопоможців',
      en: 'Join the legion of eDopomoga',
    },
    description: {
      uk: "Підтримай українські сім'ї, які потребують допомоги.",
      en: 'Support Ukrainian families in need.',
    },
    ogImage: {
      uk: 'https://social.edopomoga.gov.ua/images/metadata/badges/diamant.jpg',
      en: 'https://social.edopomoga.gov.ua/images/metadata/badges/diamant.jpg',
    },
  },

  sapphire: {
    title: {
      uk: 'Долучайся до легіону єДопоможців',
      en: 'Join the legion of eDopomoga',
    },
    description: {
      uk: "Підтримай українські сім'ї, які потребують допомоги.",
      en: 'Support Ukrainian families in need.',
    },
    ogImage: {
      uk: 'https://social.edopomoga.gov.ua/images/metadata/badges/sapphire.jpg',
      en: 'https://social.edopomoga.gov.ua/images/metadata/badges/sapphire.jpg',
    },
  },
};
