import React from 'react';
import { useLocation } from 'react-use';

import { useNavigate } from '@hooks/useNavigate';

import { Routes } from '@shared/enums';
import { useUser } from '@shared/hooks/useUser';
import { stripPathLanguage } from '@shared/utils/path';
import { ExtendedUser, PrivatePage } from '@shared/types/common.types';

import { Loader } from '@shared/ui/loader/Loader';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import { INITIAL_PATHNAME } from '@shared/constants';
import useLocalStorage from '@hooks/useLocalStorage';

interface AuthProviderProps {
  children: React.ReactElement;
  privatePages: PrivatePage[];
}

const isNeedToSpecifyPhoneNumber = (user: ExtendedUser | null, pathname: string) => {
  if (!user) {
    return false;
  }

  return user.isMember && !user.phoneNumber && !pathname.includes(Routes.AuthMergeUsers);
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ privatePages, children }) => {
  const [user, { isLoading }] = useUser();
  const navigate = useNavigate();

  const { pathname = '/' } = useLocation();
  const [initialPathnameValue, setInitialPathname] = useLocalStorage(INITIAL_PATHNAME);
  const path = stripPathLanguage(pathname);
  const isPrivatePage = privatePages.find((page) =>
    page.exact ? page.url === path : path.startsWith(page.url) || false,
  );

  /**
   * If user is not guest and doesn't have phone number, then we
   * redirect him to merge user page to specify phone number
   */
  if (isNeedToSpecifyPhoneNumber(user, pathname)) {
    navigate(Routes.AuthMergeUsers);

    return null;
  }

  // TODO: move before check isLoading quick fix
  if (!isPrivatePage) return children;

  if (isLoading) {
    return (
      <LoaderWrapper className="z-overlay">
        <Loader />
      </LoaderWrapper>
    );
  }

  if (isPrivatePage && !user?.isMember) {
    if (!initialPathnameValue) {
      setInitialPathname(pathname);
    }
    navigate(Routes.AuthSignIn, { state: { backTo: '/' } });
    return null;
  }

  return children;
};
