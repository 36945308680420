import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { applicationsAdapter } from './adapter';

const getApplicationState = (state: RootState) => state.applications;
export const applicationsSelectorsDraft = applicationsAdapter.getSelectors();
export const applicationsSelectors = applicationsAdapter.getSelectors(getApplicationState);

/**
 * Selectors
 */
export const isAllItemsInApplicationSelected = ({ applicationId, count }: { applicationId: number; count: number }) =>
  createSelector(
    (state: RootState) => applicationsSelectors.selectById(state, applicationId),
    (application) => {
      return application?.items.length === count;
    },
  );
export const selectSelectedApplications = createSelector(
  applicationsSelectors.selectAll,
  (applications) => applications,
);
export const isAnyApplicationSelected = createSelector(applicationsSelectors.selectTotal, (total) => total !== 0);
export const selectSelectedApplicationItems = createSelector(
  applicationsSelectors.selectById,
  (application) => application?.items ?? [],
);
export const selectSelectedApplicationItemsIds = createSelector(selectSelectedApplicationItems, (items) =>
  items.map((item) => item.id),
);
export const selectAllSelectedApplicationItems = createSelector(selectSelectedApplications, (applications) =>
  applications.map((application) => application.items).flat(),
);
export const selectApplicationItemsToTake = createSelector(selectAllSelectedApplicationItems, (items) =>
  items.map((item) => ({
    id: item.id,
  })),
);
