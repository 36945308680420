import React from 'react';
import { Helmet } from 'react-helmet';

import config, { AppMode } from '@config';
import { Routes } from '@shared/enums';

import defaultMetadata from '@values/metadata';
import receiptMetadata from '@values/receipt-metadata';

import legionBadgesMetadata from '@values/legion-badges-metadata';

/**
 * All home page pathnames
 */
const homePagePathnames = [
  '/',
  ...config.i18n.languages.map((l) => `/${l}`),
  ...config.i18n.languages.map((l) => `/${l}/`),
];

interface IMetaVariants {
  uk: string;
  en: string;
  pl?: string;
  de?: string;
  fr?: string;
}

interface IMetaProps {
  title: IMetaVariants;
  description: IMetaVariants;
  ogImage: IMetaVariants;
}

interface PageMetadataProps {
  children: React.ReactElement;
  props: {
    location: any;
    pageContext: any;
  };
}

const usePageMetadataLang = (language: 'uk' | 'en' | 'pl' | 'de' | 'fr', metadata: IMetaProps) => {
  const title = metadata.title[language] || metadata.title.en;
  const description = metadata.description[language] || metadata.description.en;
  const ogImage = metadata.ogImage[language];

  return {
    title,
    description,
    ogImage,
  };
};

const usePageMetadata = (pathname: string): IMetaProps => {
  if (pathname.includes(Routes.Receipts)) {
    return receiptMetadata;
  }

  if (pathname.includes(Routes.Legions)) {
    const badgeVariant = pathname.split('/').filter(Boolean).at(-1);

    // @ts-ignore
    return legionBadgesMetadata[badgeVariant] || legionBadgesMetadata.bronze;
  }

  return defaultMetadata;
};

const usePageHead = (pathname: string) => {
  /**
   * Add hrefLang links for home page links
   */
  if (homePagePathnames.includes(pathname)) {
    return [
      <link key="uk" rel="alternate" href="https://social.edopomoga.gov.ua/uk/" hrefLang="uk" />,
      <link key="en" rel="alternate" href="https://social.edopomoga.gov.ua/en/" hrefLang="en" />,
      <link key="pl" rel="alternate" href="https://social.edopomoga.gov.ua/pl/" hrefLang="pl" />,
      <link key="de" rel="alternate" href="https://social.edopomoga.gov.ua/de/" hrefLang="de" />,
      <link key="fr" rel="alternate" href="https://social.edopomoga.gov.ua/fr/" hrefLang="fr" />,
      <link key="default" rel="alternate" href={`https://social.edopomoga.gov.ua${pathname}`} hrefLang="x-default" />,
    ];
  }

  return null;
};

export const PageMetadata: React.FC<PageMetadataProps> = ({ children, props }) => {
  const { location, pageContext } = props;

  const metadata = usePageMetadata(location.pathname);
  const { title, description, ogImage } = usePageMetadataLang(pageContext.intl.language, metadata);
  const head = usePageHead(location.pathname);

  const url = `https://social.edopomoga.gov.ua${location.pathname}`;

  return (
    <>
      <Helmet title={title} defer={false}>
        <meta name="description" content={description} />

        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="314" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:description" content={description} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="social.edopomoga.gov.ua" />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={ogImage} />
        {config.mode === AppMode.Production && (
          <meta name="google-site-verification" content="PcEHI9N3aYJXm9nH42sgPvuKlxPPFWGApUNQcc8KNbM" />
        )}
        {config.mode === AppMode.Production && (
          <meta name="facebook-domain-verification" content="1hlgddfffvljz9i41i1riqiey1k9ek" />
        )}

        {head}

        {config.mode === AppMode.Staging && <meta name="robots" content="noindex" />}
        {config.mode === AppMode.Production && <script src="/scripts/hotjar.js" />}
      </Helmet>
      {children}
    </>
  );
};
