import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SocialTypes } from '@shared/enums';

interface SocialFilters {
  type: string;
}
interface SocialPagination {
  page: number;
  perPage: number;
}

interface SocialSlice {
  filters: SocialFilters;
  pagination: SocialPagination;
}

export const socialSlice = createSlice({
  name: 'social',
  initialState: {
    filters: {
      type: '',
    },
    pagination: {
      page: 1,
      perPage: 9,
    },
  } as SocialSlice,
  reducers: {
    setTypeFilter: (state, { payload }: PayloadAction<SocialTypes | string>) => {
      state.filters.type = payload;
    },
    setPaginationPage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.pagination.page = payload.page;
    },
    setPaginationPerPage: (state, { payload }: PayloadAction<{ perPage: number }>) => {
      state.pagination.perPage = payload.perPage;
    },
    resetPaginationPage: (state) => {
      state.pagination.page = 1;
    },
  },
});

export const socialReducer = socialSlice.reducer;

export const socialActions = {
  ...socialSlice.actions,
};
