import { WrapPageElement } from './src/shared/WrapPageElement';
import { WrapRootElement } from './src/shared/WrapRootElement';
import 'swiper/css';

import '@assets/css/typography.css';
import '@assets/css/global.css';

import '@assets/css/components.css';
import '@assets/css/santa-claus.css';

export const wrapRootElement = WrapRootElement;
export const wrapPageElement = WrapPageElement;

export const onRouteUpdate = () => {
  // if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  //   // ISSUE: https://github.com/gatsbyjs/gatsby/issues/9087#issuecomment-774680408
  //   navigator.serviceWorker.register('/sw.js').then((registration) => {
  //     registration.update();
  //   });
  // }
};

export const onServiceWorkerUpdateReady = () => {
  if (typeof document !== 'undefined') {
    // const root = document.getElementById('___gatsby');
    // if (!root) return;
    // root.setAttribute('data-update-available', 'true');
    // @ts-ignore
    // window.location.reload(true);
  }
};
