import * as React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const LinkedinIcon: React.FC<IconProps> = ({
  width = 36,
  height = 36,
  className = 'text-white',
  stroke = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        stroke={stroke}
        strokeWidth="2"
        d="M3.57194 3.57355L3.57355 3.57194C4.79714 2.34556 6.37314 1.68775 8.68919 1.34759C11.0392 1.00245 14.0222 1 17.9967 1C21.9712 1 24.9545 1.00243 27.3055 1.34683C29.6222 1.68621 31.2004 2.34254 32.4273 3.56622C33.6543 4.79331 34.3122 6.37149 34.6524 8.68833C34.9976 11.0391 35 14.0222 35 17.9967C35 21.9712 34.9976 24.9543 34.6524 27.3051C34.3122 29.622 33.6543 31.2002 32.4273 32.4273C31.2003 33.6542 29.6207 34.3122 27.3027 34.6524C24.9511 34.9976 21.9679 35 17.9967 35C14.0255 35 11.0424 34.9976 8.69159 34.6524C6.37446 34.3122 4.79699 33.6543 3.57355 32.4281L3.57275 32.4273C2.34581 31.2003 1.68782 29.6207 1.3476 27.3027C1.00244 24.9511 1 21.9679 1 17.9967C1 14.0255 1.00244 11.0424 1.3476 8.69159C1.68781 6.37446 2.34571 4.79699 3.57194 3.57355Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M9 10.2894C9 9.57748 9.59552 9 10.3302 9H25.6698C26.4045 9 27 9.57748 27 10.2894V25.7106C27 26.4228 26.4045 27 25.6698 27H10.3302C9.59552 27 9 26.4228 9 25.7106V10.2894ZM14.4565 24.0679V15.9399H11.7378V24.0679H14.4565ZM13.0971 14.8301C14.0452 14.8301 14.6353 14.206 14.6353 13.426C14.6177 12.6284 14.0452 12.0216 13.1151 12.0216C12.1851 12.0216 11.5771 12.6284 11.5771 13.426C11.5771 14.206 12.167 14.8301 13.0794 14.8301H13.0971ZM18.6792 24.0679H15.9606C15.9606 24.0679 15.9963 16.7025 15.9606 15.9399H18.6793V17.0906C18.6732 17.1 18.6671 17.1094 18.6611 17.1186H18.6793V17.0906C19.0406 16.5368 19.6872 15.7491 21.1294 15.7491C22.9182 15.7491 24.2594 16.9108 24.2594 19.4074V24.0679H21.5409V19.7196C21.5409 18.6269 21.1474 17.8815 20.1636 17.8815C19.4126 17.8815 18.9652 18.3842 18.7687 18.8696C18.6969 19.0432 18.6792 19.2859 18.6792 19.5288V24.0679Z"
      />
    </svg>
  );
};
