import { useUser } from '@shared/hooks/useUser';
import { FacebookIcon } from '@shared/icons/FacebookIcon';
import { LogoIcon } from '@shared/icons/logo';
import { TryzubIcon } from '@shared/icons/tryzub';
import { TwitterIcon } from '@shared/icons/TwitterIcon';
import { IconProps } from '@shared/interfaces/icon.interface';
import { ProfileDropdown } from '@shared/ui/dropdowns/ProfileDropdown';
import { GenericLink, GenericLinkTypeField, OuterLink } from '@shared/ui/GenericLink';
import { LanguageSelect } from '@shared/ui/selects/LanguageSelect';
import { Text } from '@shared/ui/text';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InstagramIcon } from '@shared/icons/InstagramIcon';

import { useGetLanguageLink } from '@hooks/useGetLanguageLink';
import { useLanguage } from '@hooks/useLanguage';
import { Button } from '@shared/ui/buttons/Button';
import { LinkedinIcon } from '@shared/icons/LinkedinIcon';
import { Link } from 'gatsby';

interface FooterLink {
  key?: string;
  type: GenericLinkTypeField;
  to: string;
  text?: string;
  Icon?: FunctionComponent<IconProps>;
  isActive: boolean | ((language: string) => boolean);
}

const footerSectionOne: FooterLink[] = [
  {
    key: 'how-to-help',
    type: 'inner',
    to: '/#how-to-help',
    text: 'how-to-help',
    isActive: true,
  },
  {
    key: 'questions-and-answers',
    type: 'inner',
    to: '/#questions-answers',
    text: 'questions-and-answers',
    isActive: true,
  },
  {
    key: 'about-us',
    type: 'inner',
    to: '/#about-us',
    text: 'about-us',
    isActive: true,
  },
];

const footerSectionTwo: FooterLink[] = [
  {
    key: 'regulatory-documents',
    type: 'page',
    to: '/documentation',
    text: 'regulatory-documents',
    isActive: true,
  },
  {
    key: 'privacy-policy',
    type: 'outer',
    to: '/documents/confidential.pdf',
    text: 'privacy-policy',
    isActive: true,
  },
];

const footerSectionThree: FooterLink[] = [
  {
    key: 'sign-in',
    type: 'page',
    to: '/auth/sign-in',
    text: 'sign-in',
    isActive: true,
  },
  {
    key: 'sign-up',
    type: 'page',
    to: '/auth/sign-up',
    text: 'sign-up',
    isActive: true,
  },
  {
    key: 'to-market',
    type: 'outer',
    to: 'https://edopomoga.customprint.market/uk',
    text: 'to-market',
    isActive: (language) => language === 'uk',
  },
  {
    key: 'to-market',
    type: 'outer',
    to: 'https://edopomoga.customprint.market/en',
    text: 'to-market',
    isActive: (language) => language !== 'uk',
  },
];

const facebookLink: FooterLink = {
  key: 'facebook',
  type: 'outer',
  to: 'https://www.facebook.com/eDopomogaGov',
  Icon: FacebookIcon,
  isActive: true,
};

const twitterLink: FooterLink = {
  key: 'twitter',
  type: 'outer',
  to: 'https://twitter.com/DopomogaE',
  Icon: TwitterIcon,
  isActive: true,
};

const instagramLink: FooterLink = {
  key: 'instagram',
  type: 'outer',
  to: 'https://instagram.com/edopomoga.gov.ua',
  Icon: InstagramIcon,
  isActive: true,
};

const linkedinLink: FooterLink = {
  key: 'linkedin',
  type: 'outer',
  to: 'https://www.linkedin.com/company/edopomoga/',
  Icon: LinkedinIcon,
  isActive: true,
};

const getFooterSectionSocials = (language: string): FooterLink[] => {
  if ('uk' === language) {
    return [facebookLink, instagramLink, twitterLink, linkedinLink];
  }

  return [twitterLink, linkedinLink, facebookLink, instagramLink];
};

const hrefMail = 'mailto:help@edopomoga.gov.ua';
const textMail = 'help@edopomoga.gov.ua';

export const Footer = () => {
  const { t } = useTranslation();
  const [user] = useUser();
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);
  const copyright = `${currentYear}. ${t('all-rights-reserved')}`;

  const getLanguageLink = useGetLanguageLink();
  const language = useLanguage();

  const isShowFooterLink = useCallback(
    (link: FooterLink) => (typeof link.isActive === 'function' ? link.isActive(language) : link.isActive),
    [language],
  );

  return (
    <footer className="py-[50px] text-white text-base bg-black">
      <div className="container_2xl flex flex-col gap-x-8">
        <div className="flex flex-col justify-between basis-full sm:flex-row sm:flex-wrap">
          <ul className="basis-2/12 list-none sm:pr-8">
            {footerSectionOne.map(({ key, type, to, text = '' }) => (
              <li key={key} className="whitespace-nowrap mb-6">
                <GenericLink type={type} to={getLanguageLink(to)}>
                  {t(text)}
                </GenericLink>
              </li>
            ))}
          </ul>
          <ul className="basis-3/12 mb-6 list-none md:pr-8">
            {footerSectionTwo.map(({ key, type, to, text = '' }) => (
              <li key={key} className="whitespace-nowrap mb-6 last:mb-0">
                <GenericLink type={type} to={to}>
                  {t(text)}
                </GenericLink>
              </li>
            ))}
          </ul>
          <ul className="basis-full mb-6 list-none md:basis-2/12">
            {user?.isMember ? (
              <li className="mb-3 md:-mt-2">
                <ProfileDropdown mode="dark" className="text-right -ml-[18px]" />
              </li>
            ) : (
              footerSectionThree.filter(isShowFooterLink).map(({ key, type, to, text = '' }) => (
                <li key={key} className="whitespace-nowrap mb-6">
                  <GenericLink type={type} to={to}>
                    {t(text)}
                  </GenericLink>
                </li>
              ))
            )}
            <li key="language" className="flex flex-row md:flex-col items-start xxl:flex-row gap-5 mb-6">
              <LanguageSelect mode="dark" menuPlacement="top" />

              <Button as="link-in" to="/donate" variant="primary-invert" size="small" className="whitespace-nowrap">
                {t('transfer-help')}
              </Button>
            </li>
          </ul>
          <div className="basis-full 2xl:basis-auto 2xl:ml-auto">
            <div className="flex mb-12 space-x-6 sm:mb-4 2xl:justify-end">
              {getFooterSectionSocials(language).map(({ key, type, to, Icon }) => (
                <GenericLink type={type} key={key} to={to}>
                  {Icon && <Icon width={36} height={36} />}
                </GenericLink>
              ))}
            </div>
            <div className="flex flex-col sm:flex-row mb-12 whitespace-nowrap 2xl:justify-end">
              <div className="mr-2">{`${t('our-contacts')}: `}</div>
              <OuterLink to={hrefMail} className="underline">
                {textMail}
              </OuterLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5 md:flex-row md:items-center md:justify-between flex-grow-[12] basis-full">
          <div className="flex items-center">
            <GenericLink className="flex mr-3" type="page" to="/">
              <TryzubIcon className="shrink-0 mr-2 w-[3rem] h-[3rem] text-white" />
              <LogoIcon className="shrink-0 w-[3rem] h-[3rem]" />
            </GenericLink>
            <Text size="xxs" className="opacity-70">
              edopomoga.gov.ua <br /> {copyright}
            </Text>
          </div>
          <GenericLink type="outer" to="https://www.ioc.gov.ua/" className="underline">
            <Text size="xxs" className="opacity-80 text-center">
              {t('supported-by')} <b>{t('min-soc')}</b>
            </Text>
          </GenericLink>
        </div>
      </div>
    </footer>
  );
};
