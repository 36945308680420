import React from 'react';

const useComponentState = (props: any) => {
  return {
    ...props,
  };
};

export const LayoutSimple: React.FC<any> = (props) => {
  const { children } = useComponentState(props);

  return <>{children}</>;
};
