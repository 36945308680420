exports.components = {
  "component---src-pages-301-[id]-tsx": () => import("./../../../src/pages/301/[id].tsx" /* webpackChunkName: "component---src-pages-301-[id]-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-merge-index-tsx": () => import("./../../../src/pages/auth/merge/index.tsx" /* webpackChunkName: "component---src-pages-auth-merge-index-tsx" */),
  "component---src-pages-auth-sign-in-index-tsx": () => import("./../../../src/pages/auth/sign-in/index.tsx" /* webpackChunkName: "component---src-pages-auth-sign-in-index-tsx" */),
  "component---src-pages-auth-sign-up-customer-tsx": () => import("./../../../src/pages/auth/sign-up/customer.tsx" /* webpackChunkName: "component---src-pages-auth-sign-up-customer-tsx" */),
  "component---src-pages-auth-sign-up-index-tsx": () => import("./../../../src/pages/auth/sign-up/index.tsx" /* webpackChunkName: "component---src-pages-auth-sign-up-index-tsx" */),
  "component---src-pages-auth-sign-up-volunteer-tsx": () => import("./../../../src/pages/auth/sign-up/volunteer.tsx" /* webpackChunkName: "component---src-pages-auth-sign-up-volunteer-tsx" */),
  "component---src-pages-auth-update-password-tsx": () => import("./../../../src/pages/auth/update-password.tsx" /* webpackChunkName: "component---src-pages-auth-update-password-tsx" */),
  "component---src-pages-auth-update-phone-tsx": () => import("./../../../src/pages/auth/update-phone.tsx" /* webpackChunkName: "component---src-pages-auth-update-phone-tsx" */),
  "component---src-pages-banners-tsx": () => import("./../../../src/pages/banners.tsx" /* webpackChunkName: "component---src-pages-banners-tsx" */),
  "component---src-pages-cabinet-customer-tsx": () => import("./../../../src/pages/cabinet/customer.tsx" /* webpackChunkName: "component---src-pages-cabinet-customer-tsx" */),
  "component---src-pages-cabinet-volunteer-tsx": () => import("./../../../src/pages/cabinet/volunteer.tsx" /* webpackChunkName: "component---src-pages-cabinet-volunteer-tsx" */),
  "component---src-pages-cert-[id]-tsx": () => import("./../../../src/pages/cert/[id].tsx" /* webpackChunkName: "component---src-pages-cert-[id]-tsx" */),
  "component---src-pages-customer-profile-[id]-tsx": () => import("./../../../src/pages/customer/profile/[id].tsx" /* webpackChunkName: "component---src-pages-customer-profile-[id]-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-documentation-index-tsx": () => import("./../../../src/pages/documentation/index.tsx" /* webpackChunkName: "component---src-pages-documentation-index-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-gratitude-[id]-tsx": () => import("./../../../src/pages/gratitude/[id].tsx" /* webpackChunkName: "component---src-pages-gratitude-[id]-tsx" */),
  "component---src-pages-help-fast-receipts-[id]-tsx": () => import("./../../../src/pages/help-fast/receipts/[id].tsx" /* webpackChunkName: "component---src-pages-help-fast-receipts-[id]-tsx" */),
  "component---src-pages-in-need-tsx": () => import("./../../../src/pages/in_need.tsx" /* webpackChunkName: "component---src-pages-in-need-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instructions-legion-tsx": () => import("./../../../src/pages/instructions/legion.tsx" /* webpackChunkName: "component---src-pages-instructions-legion-tsx" */),
  "component---src-pages-legions-bronze-tsx": () => import("./../../../src/pages/legions/bronze.tsx" /* webpackChunkName: "component---src-pages-legions-bronze-tsx" */),
  "component---src-pages-legions-diamant-tsx": () => import("./../../../src/pages/legions/diamant.tsx" /* webpackChunkName: "component---src-pages-legions-diamant-tsx" */),
  "component---src-pages-legions-gold-tsx": () => import("./../../../src/pages/legions/gold.tsx" /* webpackChunkName: "component---src-pages-legions-gold-tsx" */),
  "component---src-pages-legions-sapphire-tsx": () => import("./../../../src/pages/legions/sapphire.tsx" /* webpackChunkName: "component---src-pages-legions-sapphire-tsx" */),
  "component---src-pages-legions-silver-tsx": () => import("./../../../src/pages/legions/silver.tsx" /* webpackChunkName: "component---src-pages-legions-silver-tsx" */),
  "component---src-pages-oauth-id-gov-ua-redirect-tsx": () => import("./../../../src/pages/oauth/id-gov-ua/redirect.tsx" /* webpackChunkName: "component---src-pages-oauth-id-gov-ua-redirect-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-questions-answers-[id]-tsx": () => import("./../../../src/pages/questions-answers/[id].tsx" /* webpackChunkName: "component---src-pages-questions-answers-[id]-tsx" */),
  "component---src-pages-questions-answers-index-tsx": () => import("./../../../src/pages/questions-answers/index.tsx" /* webpackChunkName: "component---src-pages-questions-answers-index-tsx" */),
  "component---src-pages-questions-answers-legion-instruction-question-1-tsx": () => import("./../../../src/pages/questions-answers/legion-instruction-question-1.tsx" /* webpackChunkName: "component---src-pages-questions-answers-legion-instruction-question-1-tsx" */),
  "component---src-pages-questions-answers-legion-instruction-question-2-tsx": () => import("./../../../src/pages/questions-answers/legion-instruction-question-2.tsx" /* webpackChunkName: "component---src-pages-questions-answers-legion-instruction-question-2-tsx" */),
  "component---src-pages-questions-answers-legion-instruction-question-3-tsx": () => import("./../../../src/pages/questions-answers/legion-instruction-question-3.tsx" /* webpackChunkName: "component---src-pages-questions-answers-legion-instruction-question-3-tsx" */),
  "component---src-pages-questions-answers-legion-instruction-question-4-tsx": () => import("./../../../src/pages/questions-answers/legion-instruction-question-4.tsx" /* webpackChunkName: "component---src-pages-questions-answers-legion-instruction-question-4-tsx" */),
  "component---src-pages-questions-answers-legion-instruction-question-5-tsx": () => import("./../../../src/pages/questions-answers/legion-instruction-question-5.tsx" /* webpackChunkName: "component---src-pages-questions-answers-legion-instruction-question-5-tsx" */),
  "component---src-pages-questions-answers-question-category-online-help-2-tsx": () => import("./../../../src/pages/questions-answers/question-category-online-help-2.tsx" /* webpackChunkName: "component---src-pages-questions-answers-question-category-online-help-2-tsx" */),
  "component---src-pages-receipts-[id]-tsx": () => import("./../../../src/pages/receipts/[id].tsx" /* webpackChunkName: "component---src-pages-receipts-[id]-tsx" */),
  "component---src-pages-report-monthly-index-tsx": () => import("./../../../src/pages/report/monthly/index.tsx" /* webpackChunkName: "component---src-pages-report-monthly-index-tsx" */),
  "component---src-pages-santa-claus-customer-tsx": () => import("./../../../src/pages/santa-claus/customer.tsx" /* webpackChunkName: "component---src-pages-santa-claus-customer-tsx" */),
  "component---src-pages-santa-claus-foreign-volunteer-tsx": () => import("./../../../src/pages/santa-claus/foreign-volunteer.tsx" /* webpackChunkName: "component---src-pages-santa-claus-foreign-volunteer-tsx" */),
  "component---src-pages-santa-claus-letter-tsx": () => import("./../../../src/pages/santa-claus/letter.tsx" /* webpackChunkName: "component---src-pages-santa-claus-letter-tsx" */),
  "component---src-pages-santa-claus-volunteer-tsx": () => import("./../../../src/pages/santa-claus/volunteer.tsx" /* webpackChunkName: "component---src-pages-santa-claus-volunteer-tsx" */),
  "component---src-pages-social-tsx": () => import("./../../../src/pages/social.tsx" /* webpackChunkName: "component---src-pages-social-tsx" */),
  "component---src-pages-volunteer-tsx": () => import("./../../../src/pages/volunteer.tsx" /* webpackChunkName: "component---src-pages-volunteer-tsx" */)
}

