import { navigate } from 'gatsby';
import { useCallback } from 'react';

import { useGetLanguageLink } from './useGetLanguageLink';

export function useNavigate() {
  const getLanguageLink = useGetLanguageLink();

  const customNavigate: typeof navigate = useCallback(
    async (to: string | number, options = {}) => {
      /**
       * To support `-1` or `1` arguments
       */
      if (typeof to === 'number') return navigate(to);

      return navigate(getLanguageLink(to), options as any);
    },
    [getLanguageLink],
  );

  return customNavigate;
}
