import React, { ReactNode, useMemo } from 'react';
import { Link } from './Link';

export type GenericLinkTypeField = 'page' | 'inner' | 'outer';

export type GenericLinkProps = {
  className?: string;
  type: GenericLinkTypeField;
  to: string;
  children?: ReactNode;
  onClick?: () => void;
};

interface InnerLinkProps {
  className?: string;
  to: string;
  children?: ReactNode;
}

interface OuterLinkProps {
  className?: string;
  to: string;
  children?: ReactNode;
}

export const InnerLink = (props: InnerLinkProps) => {
  const { className = '', to, children } = props;

  return (
    <a className={className} href={to}>
      {children}
    </a>
  );
};

export const OuterLink = (props: OuterLinkProps) => {
  const { className = '', to, children } = props;

  return (
    <a className={className} href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const linksByType = {
  page: Link,
  inner: InnerLink,
  outer: OuterLink,
};

export const GenericLink = (props: GenericLinkProps) => {
  const { className = '', type, to, children, ...rest } = props;

  const LinkTag = useMemo(() => linksByType[type], [type]);

  return (
    <LinkTag className={className} to={to} {...rest}>
      {children}
    </LinkTag>
  );
};
