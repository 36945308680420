import * as React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const TwitterIcon: React.FC<IconProps> = ({ width = 36, height = 36, className = 'text-white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.57194 3.57355L3.57355 3.57194C4.79714 2.34556 6.37314 1.68775 8.68919 1.34759C11.0392 1.00245 14.0222 1 17.9967 1C21.9712 1 24.9545 1.00243 27.3055 1.34683C29.6222 1.68621 31.2004 2.34254 32.4273 3.56622C33.6543 4.79331 34.3122 6.37149 34.6524 8.68833C34.9976 11.0391 35 14.0222 35 17.9967C35 21.9712 34.9976 24.9543 34.6524 27.3051C34.3122 29.622 33.6543 31.2002 32.4273 32.4273C31.2003 33.6542 29.6207 34.3122 27.3027 34.6524C24.9511 34.9976 21.9679 35 17.9967 35C14.0255 35 11.0424 34.9976 8.69159 34.6524C6.37446 34.3122 4.79699 33.6543 3.57355 32.4281L3.57275 32.4273C2.34581 31.2003 1.68782 29.6207 1.3476 27.3027C1.00244 24.9511 1 21.9679 1 17.9967C1 14.0255 1.00244 11.0424 1.3476 8.69159C1.68781 6.37446 2.34571 4.79699 3.57194 3.57355Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M25.1498 13.7383C25.1612 13.9023 25.1612 14.0664 25.1612 14.2305C25.1612 19.2344 21.4493 25 14.665 25C12.5749 25 10.6333 24.3789 9 23.3008C9.29697 23.3359 9.58247 23.3477 9.89086 23.3477C11.6155 23.3477 13.2031 22.75 14.4708 21.7305C12.849 21.6953 11.4898 20.6055 11.0216 19.1055C11.25 19.1406 11.4784 19.164 11.7183 19.164C12.0495 19.164 12.3807 19.1172 12.6891 19.0352C10.9987 18.6836 9.73094 17.1601 9.73094 15.3203V15.2734C10.222 15.5547 10.7932 15.7305 11.3984 15.7539C10.4048 15.0742 9.75379 13.914 9.75379 12.6015C9.75379 11.8984 9.9365 11.2539 10.2563 10.6914C12.0723 12.9883 14.802 14.4882 17.8629 14.6523C17.8058 14.3711 17.7716 14.0781 17.7716 13.7852C17.7716 11.6992 19.4162 10 21.4607 10C22.5228 10 23.4822 10.457 24.1561 11.1953C24.9898 11.0313 25.7893 10.7148 26.4975 10.2813C26.2233 11.1602 25.6409 11.8985 24.8756 12.3672C25.618 12.2852 26.3376 12.0742 27 11.7813C26.4975 12.5312 25.8693 13.1992 25.1498 13.7383Z"
        fill="currentColor"
      />
    </svg>
  );
};
