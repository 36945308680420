import * as React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const LogoIcon: React.FC<IconProps> = ({ width = 35, height = 35, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.11 32c-9.076 0-11.52-.455-13.635-2.543C1.347 27.357.9 24.959.9 16S1.36 4.641 3.475 2.541C5.591.454 8.034 0 17.11 0s11.52.454 13.634 2.542C32.873 4.642 33.32 7.04 33.32 16s-.446 11.358-2.575 13.458C28.63 31.545 26.186 32 17.11 32z"
        fill="url(#paint0_linear_196_1757)"
      />
      <path
        d="M19.47 19.776c-.656.445-1.412.643-2.27.643-1.11 0-1.967-.297-2.673-.94s-1.16-1.534-1.312-2.672h5.8v-2.573h-5.75c.202-.99.656-1.83 1.363-2.424.706-.594 1.563-.89 2.572-.89.858 0 1.614.247 2.27.692a4.502 4.502 0 011.463 1.83l3.278-1.137c-.504-1.336-1.362-2.375-2.572-3.167C20.378 8.396 18.915 8 17.25 8c-2.32 0-4.186.742-5.598 2.127-1.413 1.386-2.119 3.266-2.119 5.542 0 2.325.706 4.206 2.119 5.591 1.412 1.385 3.278 2.078 5.598 2.078 1.665 0 3.128-.346 4.389-1.138 1.21-.742 2.068-1.781 2.572-3.166l-3.278-1.138c-.354.791-.858 1.434-1.463 1.88z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="paint0_linear_196_1757"
          x1={3.07672}
          y1={28.9992}
          x2={30.071}
          y2={1.48002}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#017C67" />
          <stop offset={0.5} stopColor="#01A270" />
          <stop offset={1} stopColor="#A0CA6E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
