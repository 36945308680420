import { User } from '@shared/types/common.types';

import { useAppDispatch } from '@store/hooks';
import { setUser } from '@store/slices/auth';

import useLocalStorage from './useLocalStorage';

export function useLogIn() {
  const dispatch = useAppDispatch();
  const [_, setAuthorization] = useLocalStorage('authorization');

  return (user: User, token: string | null = null) => {
    dispatch(setUser(user));

    if (token) {
      setAuthorization(token);
    }
  };
}
