import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { components, MenuPlacement } from 'react-select';

import { SecondaryDropdown } from '@shared/dropdowns/secondary';
import { Element } from '@shared/interfaces/element.interface';
import { Option } from '@shared/interfaces/option.interface';

import { Routes } from '@shared/enums';
import { useNavigate } from '@hooks/useNavigate';
import { useUser } from '@shared/hooks/useUser';

interface ApplicationsSelectProps extends Element {
  menuPlacement?: MenuPlacement;
  onRouteChange?: (route: string) => void;
}

function SingleValue(props: any) {
  const { t } = useTranslation();

  return (
    <components.SingleValue {...props}>
      <div className="normal-case">Заявки</div>
    </components.SingleValue>
  );
}

export const ApplicationsSelect: React.FC<ApplicationsSelectProps> = ({
  className,
  menuPlacement = 'bottom',
  onRouteChange,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [user] = useUser();

  const applications = React.useMemo(
    () =>
      [
        { value: Routes.OnlineHelp, label: t('help-online'), active: user },
        { value: Routes.HelpByHand, label: t('help-by-hands-action-card-title'), active: user },
        { value: Routes.CustomerCabinet, label: t('get-help'), active: user?.isMember },
      ].filter((option) => option.active),
    [t, user],
  );

  const navigate = useNavigate();

  const onChange = async (option: Option | null) => {
    if (!option) return;

    navigate(option.value);

    if (!onRouteChange) return;
    await Promise.resolve(onRouteChange(option.value));
  };

  if (language !== 'uk') return null;

  return (
    <SecondaryDropdown
      options={applications}
      className={className}
      defaultValue={applications[0]}
      onChange={onChange}
      menuPlacement={menuPlacement}
      components={{ SingleValue }}
      style={{
        menu: {
          left: '-75%',
        },
      }}
    />
  );
};
