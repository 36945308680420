export default {
  title: {
    uk: 'Отримайте допомогу, допоможіть з рук в руки, або онлайн',
    en: 'Help Ukrainians online',
    pl: 'Pomóż Ukraińcom online',
    de: 'Helfen Sie den Ukrainern online',
    fr: 'Aidez les Ukrainiens en ligne',
  },
  description: {
    uk: 'На платформі єДопомога ви можете залишити заявку на отримання допомоги, допомогти іншим передавши необхідні речі з рук в руки, або допомогти онлайн',
    en: 'Help people suffering from russian invasion into Ukraine',
    pl: 'Pomóż ludziom, którzy ucierpieli od rosyjskiej inwazji na Ukrainę',
    de: 'Helfen Sie Menschen, die unter der russischen Invasion in der Ukraine leiden',
    fr: "Aidez les personnes souffrant de l'invasion russe en Ukraine",
  },
  ogImage: {
    uk: 'https://social.edopomoga.gov.ua/images/metadata/og-image-uk.jpg',
    en: 'https://social.edopomoga.gov.ua/images/metadata/og-image-en.jpg',
    pl: 'https://social.edopomoga.gov.ua/images/metadata/og-image-pl.jpg',
    de: 'https://social.edopomoga.gov.ua/images/metadata/og-image-de.jpg',
    fr: 'https://social.edopomoga.gov.ua/images/metadata/og-image-fr.jpg',
  },
};
