import React from 'react';
import { VscClose } from 'react-icons/vsc';

import { useNavigate } from '@hooks/useNavigate';
import { HeaderBase } from '@widgets/header/HeaderBase';

const useComponentState = (props: any) => {
  return {
    ...props,
  };
};

export const LayoutVolunteer: React.FC<any> = (props) => {
  const { children } = useComponentState(props);
  const navigate = useNavigate();

  return (
    <>
      <HeaderBase>
        <button className="ml-auto" onClick={() => navigate('/cabinet/volunteer')}>
          <VscClose size="34px" title="Go to previous page" />
        </button>
      </HeaderBase>

      <main className="main">{children}</main>
    </>
  );
};
