import clsx from 'clsx';
import React from 'react';

export const Loader = ({ className }: { className?: string }) => (
  <svg
    className={clsx('animate-spin', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="50px"
    height="50px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style={{
      display: 'block',
      backgroundRepeat: 'initial',
    }}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#525ea8"
      strokeWidth="7"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
      transform="matrix(1,0,0,1,0,0)"
      style={{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }}
    ></circle>
  </svg>
);
