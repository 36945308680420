import * as React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const TryzubIcon: React.FC<IconProps> = ({ width = 35, height = 35, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.533 26.92a6.057 6.057 0 01-2.315-3.729H9.439V7.071c2.031 1.002 3.495 3.208 3.657 5.494l.568 4.532-.284-.041c-.61 0-1.138.561-1.138 1.122 0 .522.407.963.935 1.083l.69.161c1.138-2.125 1.788-4.21 1.788-6.255 0-1.845-.243-3.69-.285-5.574 0-.843.325-1.604.854-2.206.528.602.853 1.363.853 2.206 0 1.884-.285 3.728-.285 5.574 0 2.044.65 4.13 1.788 6.255l.69-.16c.529-.12.935-.562.935-1.084 0-.56-.528-1.122-1.137-1.122l-.284.04.569-4.531c.284-2.286 1.624-4.492 3.655-5.494v16.12H19.23c-.284 1.524-1.097 2.887-2.316 3.73-.284.2-.528.44-.69.762-.163-.321-.407-.562-.69-.762zm-4.306-9.303c.162-.6.609-1.122 1.178-1.443l-.447-3.729a5.86 5.86 0 00-1.382-3.047v8.22h.65zm1.91 4.452c0-.562.08-1.083.202-1.604l-.528-.12c-.772-.241-1.38-.842-1.584-1.604h-.65v3.328h2.56zm2.518 0c0-.682-.528-1.284-1.219-1.363a5.034 5.034 0 00-.203 1.363h1.422zm0 1.122h-1.26c.203.883.65 1.684 1.26 2.367V23.19zm1.788-3.488c-.488-.883-.934-1.805-1.218-2.767-.285.962-.732 1.884-1.22 2.767.488.12.895.441 1.22.802.325-.36.73-.681 1.218-.802zm.771 2.366c0-.482-.081-.923-.203-1.363-.69.08-1.219.681-1.219 1.363h1.422zm-.162 1.122h-1.26v2.367c.61-.683 1.057-1.485 1.26-2.367zm3.819-1.122V18.74h-.65c-.204.762-.813 1.364-1.584 1.603l-.529.121c.122.52.203 1.042.203 1.604h2.56zm0-4.452v-8.22c-.73.842-1.26 1.884-1.382 3.047l-.447 3.729c.569.32 1.016.842 1.179 1.443h.65v.001z"
        fill="currentColor"
      />
      <path
        d="M16.055.649c6.923 0 10.737 0 13.145 2.375C31.607 5.401 31.607 9.165 31.607 16s0 10.6-2.407 12.975-6.22 2.375-13.145 2.375-10.739 0-13.145-2.375C.504 26.599.504 22.834.504 15.999s0-10.598 2.407-12.975C5.317.65 9.131.65 16.055.65zm0-.497c-7.035 0-10.946 0-13.5 2.522C0 5.194 0 9.054 0 15.999c0 6.944 0 10.805 2.555 13.327 2.555 2.521 6.465 2.521 13.5 2.521 7.037 0 10.947 0 13.502-2.521 2.554-2.522 2.554-6.383 2.554-13.327s0-10.805-2.554-13.325C27.002.152 23.09.152 16.055.152z"
        fill="currentColor"
      />
    </svg>
  );
};
