import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from '@shared/enums';

interface CustomerProfileSlice {
  filters: {
    state: ApplicationState | null;
  };

  pagination: {
    page: number;

    perPage: number;
  };
}

export const customerProfileSlice = createSlice({
  name: 'customerProfile',
  initialState: {
    filters: {
      state: null,
    },
    pagination: {
      page: 1,
      perPage: 10,
    },
  } as CustomerProfileSlice,
  reducers: {
    reloadFilters: (state) => {
      state.filters = { ...state.filters };
    },

    setStateFilter: (state, { payload }: PayloadAction<ApplicationState | null>) => {
      state.filters.state = payload;
    },

    setPaginationPage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.pagination.page = payload.page;
    },

    setPaginationPerPage: (state, { payload }: PayloadAction<{ perPage: number }>) => {
      state.pagination.perPage = payload.perPage;
    },

    resetPaginationPage: (state) => {
      state.pagination.page = 1;
      state.pagination.perPage = 10;
    },
  },
});

export const customerProfileReducer = customerProfileSlice.reducer;

export const customerProfileActions = {
  ...customerProfileSlice.actions,
};
