import * as React from 'react';

import { Element } from '@shared/interfaces/element.interface';

interface ErrorListProps extends Element {
  errors: string[];
  maxErrors?: number;
}

export const ErrorList: React.FC<ErrorListProps> = ({ errors, maxErrors = Infinity, className }) => {
  if (errors.length <= 0 || maxErrors <= 0) return null;

  return (
    <div className={className}>
      {errors.slice(0, maxErrors).map((error) => (
        <p className="text-carnation text-sm mb-2 last:mb-0" key={error}>
          {error}
        </p>
      ))}
    </div>
  );
};
