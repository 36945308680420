import { useSelector } from 'react-redux';

import { selectAuthUser } from '../store/slices/auth';

/**
 * Hook to retrieve user from store
 */
export default function useMe() {
  return useSelector(selectAuthUser);
}
