import { useAppDispatch } from '@store/hooks';
import { setUser } from '@store/slices/auth';
import { volunteerActions } from '@store/slices/volunteer/slice';

import { useNavigate } from './useNavigate';

export function useLogOut() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return async () => {
    navigate('/');

    localStorage.removeItem('authorization');

    /**
     * NOTE: clear filters after log out
     */
    dispatch(volunteerActions.resetFilters());

    dispatch(setUser(null));
  };
}
