import { configureStore } from '@reduxjs/toolkit';
import { emptySplitApi } from './emptySplitApi';
import { save, load } from 'redux-localstorage-simple';

import { applicationsReducer } from './slices/applications/slice';
import { authReducer } from './slices/auth';
import { customerReducer } from './slices/customer/slice';
import { socialReducer } from './slices/social/slice';
import { volunteerReducer } from './slices/volunteer/slice';
import { customerProfileReducer } from './slices/customer-profile/slice';

import { isBrowser } from '@shared/utils/dom';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    applications: applicationsReducer,
    customer: customerReducer,
    volunteer: volunteerReducer,
    social: socialReducer,
    customerProfile: customerProfileReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(emptySplitApi.middleware, save({ states: ['volunteer'], namespace: 'edopomoga' })),
  preloadedState: isBrowser() ? load({ states: ['volunteer'], namespace: 'edopomoga' }) : undefined,
  // disabled for all environments
  devTools: false,
});

// Infer the `RootState` and `AppDispatch` types from the store  itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
