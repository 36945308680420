import React from 'react';

import { PageMetadata } from '@widgets/metadata/PageMetadata';
import PageLayout from '@widgets/page-layout';

export const WrapPageElement = (props: any) => {
  return (
    <PageMetadata {...props}>
      <PageLayout {...props} />
    </PageMetadata>
  );
};
