import { LinkOption } from '@shared/interfaces/link-option.interface';

export default [
  {
    url: '/#how-to-help',
    text: 'how-to-help',
  },
  {
    url: '/#questions-answers',
    text: 'questions-and-answers',
  },
  {
    url: '/#about-us',
    text: 'about-us',
  },
  {
    url: '/dashboard',
    text: 'analytics',
  },
  // {
  //   url: '/donate',
  //   text: 'donate',
  // },
  // {
  //   url: '/social',
  //   text: 'our-socials',
  // },
] as LinkOption[];
