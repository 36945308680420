import { filterObjectWithPositiveFields } from '@shared/utils';
import { emptySplitApi } from '../emptySplitApi';
import { CustomerFeedbacks } from '@widgets/customer/types';

export enum ContactRequestStatus {
  Pending = 'pending',
  Created = 'created',
  Approved = 'approved',
  NotApproved = 'not-approved',
}

export type ContactReqUser = {
  id: number;
  avatar: string | null;
  allowShareEmail: boolean;
  allowSharePhone: boolean;
  email?: string;
  phone?: string;
};

export type ContactReqFullUser = {
  id: number;
  name: string;
  avatar: string | null;
  allowShareEmail: boolean;
  allowSharePhone: boolean;
  email?: string;
  phone?: string;
  oauth: string[];
  description: string;
  feedbacks: CustomerFeedbacks;
  address: any;
  interDescription: string | null | undefined;
};

export type MyContactRequest = {
  items: {
    id: number;
    message: string;
    status: string;
    volunteer: ContactReqUser;

    isDeliveredToVolunteer: boolean;
  }[];
};

export type MySendContactRequest = {
  items: {
    id: number;
    message: string;
    userInNeed: ContactReqUser;
    status: string;
    isDeliveredToVolunteer: boolean;
  }[];
};

type Meta = {
  total: number;
  page: number;
  perPage: number;
};

type MySendContactFullRequest = {
  items: {
    id: number;
    message: string;
    status: string;
    userInNeed: ContactReqFullUser;
    isDeliveredToVolunteer: boolean;
  }[];
  meta: Meta;
};

type MyContactFullRequest = {
  items: {
    id: number;
    message: string;
    status: string;
    volunteer: ContactReqFullUser;
    isDeliveredToVolunteer: boolean;
  }[];
  meta: Meta;
};

const extendedApi = emptySplitApi
  .enhanceEndpoints({
    addTagTypes: ['ContactRequest', 'MySendContactRequest'],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getContactRequest: build.query<MyContactRequest, void>({
        query: () => ({ url: '/contact-request/as-user-in-need' }),
        providesTags: () => ['ContactRequest'],
      }),
      getMySendContactRequest: build.query<MySendContactRequest, void>({
        query: () => ({ url: '/contact-request/as-volunteer' }),
        providesTags: () => ['ContactRequest'],
      }),
      getMySendContactRequestsWithPagination: build.query<MySendContactFullRequest, { page: number; perPage: number }>({
        query: (params) => {
          const filteredParams = { page: String(params.page), perPage: String(params.perPage) };
          return { url: '/contact-request/my-send-requests', params: filteredParams };
        },
        providesTags: () => ['MySendContactRequest'],
      }),
      getSendContactRequestsWithPagination: build.query<MyContactFullRequest, { page: number; perPage: number }>({
        query: (params) => {
          const filteredParams = { page: String(params.page), perPage: String(params.perPage) };
          return { url: '/contact-request/my-requests', params: filteredParams };
        },
        providesTags: () => ['MySendContactRequest'],
      }),
      createContactRequest: build.mutation<void, { userInNeedId: number; message: string }>({
        query: (body) => ({
          url: '/contact-request/create',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['MySendContactRequest', 'ContactRequest'],
      }),
      deleteContactRequest: build.mutation<void, { id: number }>({
        query: ({ id }) => ({
          url: `/contact-request/delete/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['MySendContactRequest'],
      }),
      updatePendingStatus: build.mutation<void, { items: number[] }>({
        query: (body) => ({
          url: `/contact-request/update-pending-status`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['MySendContactRequest'],
      }),
      updateContactRequestStatus: build.mutation<void, { status: ContactRequestStatus; id: number }>({
        query: ({ status, id }) => ({
          url: `/contact-request/update-status/${id}`,
          method: 'PATCH',
          body: { status },
        }),
        invalidatesTags: ['MySendContactRequest'],
      }),
      updateContactRequestDelivered: build.mutation<void, { contactRequestIds: number[] }>({
        query: (body) => ({
          url: `/contact-request/update-delivered`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['ContactRequest'],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetContactRequestQuery,
  useGetMySendContactRequestQuery,
  useCreateContactRequestMutation,
  useGetMySendContactRequestsWithPaginationQuery,
  useDeleteContactRequestMutation,
  useGetSendContactRequestsWithPaginationQuery,
  useUpdatePendingStatusMutation,
  useUpdateContactRequestStatusMutation,
  useUpdateContactRequestDeliveredMutation,
} = extendedApi;
