export default {
    title: {
      uk: 'Долучайся до Легіону єДопоможців!',
      en: 'Join the Legion of Benefactors!',
      pl: 'Join the Legion of Benefactors!',
      de: 'Join the Legion of Benefactors!',
      fr: 'Join the Legion of Benefactors!',
    },
    description: {
      uk: 'Я допоміг потребуючій родині на платформі єДопомога! Спробуй і ти! Всього декілька кліків рятують потребуючу сім\'ю!',
      en: 'I helped a needy family on the eDopomoga platform! Try it too! Just a few clicks help a family in need!',
      pl: 'I helped a needy family on the eDopomoga platform! Try it too! Just a few clicks help a family in need!',
      de: 'I helped a needy family on the eDopomoga platform! Try it too! Just a few clicks help a family in need!',
      fr: "I helped a needy family on the eDopomoga platform! Try it too! Just a few clicks help a family in need!",
    },
    ogImage: {
      uk: 'https://social.edopomoga.gov.ua/images/metadata/receipt-og-image-uk.jpg',
      en: 'https://social.edopomoga.gov.ua/images/metadata/receipt-og-image-en-new.jpg',
      pl: 'https://social.edopomoga.gov.ua/images/metadata/receipt-og-image-en.jpg',
      de: 'https://social.edopomoga.gov.ua/images/metadata/receipt-og-image-en.jpg',
      fr: 'https://social.edopomoga.gov.ua/images/metadata/receipt-og-image-en.jpg',
    },
  };
  