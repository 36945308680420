import { useLogOut } from '@hooks/useLogOut';
import { useNavigate } from '@hooks/useNavigate';
import { SecondaryDropdown } from '@shared/dropdowns/secondary';
import { Routes } from '@shared/enums';
import { useUser } from '@shared/hooks/useUser';
import { Element } from '@shared/interfaces/element.interface';
import { Option } from '@shared/interfaces/option.interface';
import { shortenUserName } from '@shared/utils/strings';
import { useContactRequestData } from '@widgets/profile/useContactRequestData';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuPlacement } from 'react-select';

interface IProfileDropdown extends Element {
  mode?: 'dark' | 'default';
  onSelect?: () => void;
  menuPlacement?: MenuPlacement;
}

export const ProfileDropdown: React.FC<IProfileDropdown> = ({
  mode,
  className,
  onSelect,
  menuPlacement = 'bottom',
}) => {
  const [user] = useUser();

  const { count } = useContactRequestData();

  const logOut = useLogOut();
  const defaultValue =
    user && user.isMember ? { value: '', label: user.contactName ? shortenUserName(user.contactName) : 'User' } : null;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const options = useMemo(
    () => [
      {
        value: 'profile',
        label:
          count !== 0
            ? ((
                <div>
                  {t('my-profile')}
                  <span className="inline-block w-2 h-2 ml-2 bg-blue rounded-full" />
                </div>
              ) as unknown as string)
            : t('my-profile'),
      },
      { value: 'change-pass', label: t('change-password') },
      { value: 'change-phone', label: t('change-phone') },
      { value: 'sign-out', label: t('sign-out') },
    ],
    [t, count],
  );

  const onChange = (option: Option | null) => {
    if (!option) return;
    const selected = option.value;

    switch (selected) {
      case 'profile':
        navigate(Routes.Profile);
        break;
      case 'change-pass':
        navigate(Routes.AuthUpdatePass);
        break;
      case 'change-phone':
        navigate(Routes.AuthUpdatePhone);
        break;
      case 'sign-out':
        logOut();
        break;
      default:
        break;
    }

    if (onSelect) {
      onSelect();
    }
  };

  if (!user?.isMember) return null;
  return (
    <div className="relative">
      {count !== 0 && (
        <div
          className="absolute w-6 h-6 text-white flex items-center justify-center bg-blue rounded-full"
          style={{ top: -5, right: -5, zIndex: 10 }}
        >
          {count}
        </div>
      )}
      <SecondaryDropdown
        defaultValue={defaultValue}
        options={options}
        className={className}
        mode={mode}
        variant="dropdown-menu"
        onChange={onChange}
        menuPlacement={menuPlacement}
      />
    </div>
  );
};
