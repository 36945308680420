import * as React from 'react';
import clsx from 'clsx';

import { Element } from '@shared/interfaces/element.interface';
import { Link } from '../Link';

type ComponentVariant = 'button' | 'link-out' | 'link-in';
type ButtonSize = 'large' | 'small' | 'tiny' | 'medium';
type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'rich-blue'
  | 'light-blue'
  | 'primary-invert'
  | 'primary-invert-2'
  | 'chip'
  | 'chip-2'
  | 'rich-blue-2';

export interface CommonButtonProps extends Element {
  as?: ComponentVariant;
  size?: ButtonSize;
  variant?: ButtonVariant;
  children?: React.ReactNode;
  icon?: null | React.ReactNode;
  iconClassName?: string;
}

export interface ButtonProps extends CommonButtonProps {
  as: 'button';
  type?: 'submit' | 'button';
  disabled?: boolean;
  onClick?: (evt: MouseEvent) => void;
}
interface LinkInProps extends CommonButtonProps {
  as: 'link-in';
  to: string;
  onClick?: () => void;
}
interface LinkOutProps extends CommonButtonProps {
  as: 'link-out';
  href: string;
  target?: string;
  rel?: string;
}

const styleConfig = {
  variant: {
    primary: 'relative text-white bg-black hover:bg-opacity-60 active:bg-opacity-70 disabled:bg-opacity-20',
    'primary-invert': 'relative text-black bg-white hover:bg-opacity-90 active:bg-opacity-70 disabled:bg-opacity-20',
    'primary-invert-2':
      'relative text-black bg-white border-black border-2 hover:bg-opacity-90 active:bg-opacity-70 disabled:bg-opacity-20',
    'rich-blue': 'relative text-white bg-rich-blue hover:bg-opacity-60 active:bg-opacity-70 disabled:bg-opacity-20',

    'light-blue': 'relative text-white bg-light-blue hover:bg-opacity-60 active:bg-opacity-70 disabled:bg-opacity-20',
    chip: 'relative text-black bg-alice-blue border-transparent border-2 hover:bg-opacity-60 active:bg-opacity-70 disabled:bg-opacity-20',
    'chip-2':
      'relative text-black bg-alice-blue border-alice-blue border-2 hover:bg-opacity-60 active:bg-opacity-70 disabled:bg-opacity-20',
    secondary:
      'relative peer text-black hover:bg-black/[.07] active:bg-black/[.14] disabled:bg-transparent disabled:text-black-50',

    'rich-blue-2':
      'relative border-rich-blue border-2 text-rich-blue hover:bg-rich-blue hover:text-white active:bg-rich-blue disabled:opacity-70 disabled:bg-opacity-0 disabled:hover:text-rich-blue',
  },
  size: {
    large: 'text-base py-4 px-10 rounded-3xl',
    small: 'text-xs py-3 px-6 rounded-xl',
    tiny: 'text-xs py-2 px-5 rounded-xl',
    medium: 'text-sm py-[15px] px-6 rounded-xl',
  },
};

function toClassNames(props: Pick<CommonButtonProps, 'size' | 'variant' | 'className' | 'icon'>) {
  const { size, variant, className, icon } = props;

  return clsx(
    'inline-block text-center transition-colors',
    styleConfig.variant[variant!],
    styleConfig.size[size!],
    className,
  );
}

const IconBasic = ({ icon: Icon, iconClassName }) => {
  if (!Icon) return null;
  return <Icon className={clsx('h-auto transition-colors peer-disabled:stroke-black-50', iconClassName)} />;
};

/* TODO: remove any */
const ButtonRegular: React.FC<any> = (props) => {
  const { children, icon, iconClassName, ...restProps } = props;

  return (
    <button {...restProps}>
      <IconBasic icon={icon} iconClassName={iconClassName} />
      {children}
    </button>
  );
};

const ButtonLinkInside: React.FC<any> = (props) => {
  const { children, icon, iconClassName, ...restProps } = props;

  return (
    <Link {...restProps}>
      <IconBasic icon={icon} iconClassName={iconClassName} />
      {children}
    </Link>
  );
};

const ButtonLinkOutside: React.FC<any> = (props) => {
  const { children, icon, iconClassName, ...restProps } = props;

  return (
    <a {...restProps}>
      <IconBasic icon={icon} iconClassName={iconClassName} />
      {children}
    </a>
  );
};

const buttonComponentConfig = {
  button: ButtonRegular,
  'link-in': ButtonLinkInside,
  'link-out': ButtonLinkOutside,
};

export const Button: React.FC<ButtonProps | LinkOutProps | LinkInProps> = (props) => {
  const {
    as = 'button',
    size = 'large',
    variant = 'primary',
    className = '',
    icon = null,
    iconClassName = '',
    ...restProps
  } = props;
  const classNames = toClassNames({ size, variant, icon, className });
  const ButtonComponent = buttonComponentConfig[as];

  return <ButtonComponent {...restProps} icon={icon} iconClassName={iconClassName} className={classNames} />;
};
