import * as React from 'react';

interface UseToggleOptions {
  defaultValue?: boolean;
}

export function useToggle(options: UseToggleOptions = {}) {
  const { defaultValue = true } = options;
  const [value, setValue] = React.useState(defaultValue);

  const toggleValue = React.useCallback(() => {
    setValue((c) => !c);
  }, [setValue]);

  return [value, toggleValue] as const;
}
