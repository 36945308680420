import { FIELD_REG_CODE } from '@widgets/signUp/signUpVolunteer/signUpVolunteerLegalPerson/constants';
import * as yup from 'yup';

interface checkIsFieldsValidProps {
  fieldList: string[];
  getFieldState: (item: string) => void;
}

export function mergeStrings(...strings) {
  const totalString = strings.join(' ');

  const uniqWords = new Set();

  totalString.split(' ').forEach((word) => uniqWords.add(word.trim()));

  const result = [...uniqWords].join(' ').trim();

  return result;
}

export function checkIsFieldsValid({ fieldList, getFieldState }: checkIsFieldsValidProps) {
  return fieldList.every((item) => !getFieldState(item).invalid);
}

export function filterObjectWithPositiveFields(inputObject) {
  const outputObject = Object.keys(inputObject).reduce((result, key) => {
    if (inputObject[key]) {
      result[key] = inputObject[key];
    }
    return result;
  }, {});

  return outputObject;
}

export function getParamArrayOfIds(data: any, filterElement: any) {
  let newData = [...data];
  if (filterElement) {
    newData = newData.filter((item) => item !== filterElement);
  }

  if (!newData.length) return null;
  return newData;
}

export function isEmptyObject(value) {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

const digitsOnly = (value) => /^\d+$/.test(value);

export function getRegCodeValidation(language: string) {
  const defaultRegCodeValidation = yup.string().trim().test('Digits only', 'errors.only-digits-allowed', digitsOnly);

  const regCodeValidation =
    language === 'uk'
      ? defaultRegCodeValidation.min(8, 'errors.usreou-length').max(8, 'errors.usreou-length')
      : defaultRegCodeValidation;

  return regCodeValidation;
}

export const getTimesForText = (times: number) => {
  const lastPart = Math.abs(Number(String(times).at(-1)) || 0);

  if (lastPart <= 0) {
    return 'times';
  }

  if (lastPart <= 1) {
    return 'time';
  }

  if (lastPart <= 4) {
    return 'times-2';
  }

  return 'times';
};

export const getYearsForText = (years: number) => {
  if (years <= 0) {
    return 'years-2';
  }

  if (years === 1) {
    return 'year';
  }

  if (years <= 4) {
    return 'years';
  }

  return 'years-2';
};
