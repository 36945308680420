import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useLogIn } from '@hooks/useLogIn';
import useMe from '@hooks/useMe';

import { useLazyMeQuery } from '@store/api/user.api';
import { useAppDispatch } from '@store/hooks';
import { selectIsFetching, selectIsInitialized, setIsFetching, setIsInitialized } from '@store/slices/auth';

import { ExtendedUser } from '@shared/types/common.types';
import { toExtendedUser } from '@shared/utils/user';

type UseUserResult = readonly [ExtendedUser | null, { isLoading: boolean }];

/**
 * Hook to get current authorized user.
 *
 * First time hook makes request to retrieve user and store in the state.
 * Next times hook uses cached value from the state.
 */
export function useUser(): UseUserResult {
  /**
   * If we have already checked user or not
   */
  const isInitialized = useSelector(selectIsInitialized);
  /**
   * If we are checking user or not
   */
  const isFetching = useSelector(selectIsFetching);

  const user = useMe();
  const logIn = useLogIn();
  const dispatch = useAppDispatch();

  const [getMe, options] = useLazyMeQuery();
  const isLoading = options.isLoading || options.isFetching || !isInitialized;

  useEffect(() => {
    if (isInitialized || isFetching) return;

    async function getUserData() {
      try {
        dispatch(setIsFetching(true));

        // TODO: check if we have `authorization` in LocalStorage

        const { data } = await getMe();
        if (!data) return;

        logIn(data);
      } finally {
        dispatch(setIsInitialized(true));
        dispatch(setIsFetching(false));
      }
    }

    (async () => {
      await getUserData();
    })();
  }, [isInitialized, isFetching]);

  return useMemo(() => {
    const extendedUser: ExtendedUser | null = user ? toExtendedUser(user) : null;

    return [extendedUser, { isLoading }] as const;
  }, [user, isLoading]);
}
