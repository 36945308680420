import React, { useEffect, useState } from 'react';

import { useNavigate } from '@hooks/useNavigate';
import { useLanguage } from '@hooks/useLanguage';

import { extractPathLanguage, getFullPathname } from '@shared/utils/path';

interface LanguageProviderProps {
  children?: React.ReactNode;
}

/**
 * TODO: temporary fix in future we remove this provider at all
 */
const ignorePages = ['/oauth/id-gov-ua/redirect'];

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const pathLanguage = typeof window !== 'undefined' ? extractPathLanguage(window.location.pathname) : null;

  const language = useLanguage();

  const navigate = useNavigate();
  const [languageInitialized, setLanguageInitialized] = useState(pathLanguage === language);

  useEffect(() => {
    if (languageInitialized) return;

    const fullPathname = getFullPathname();
    if (ignorePages.some((page) => fullPathname.includes(page))) {
      return;
    }

    if (pathLanguage !== language) {
      if (location.pathname.endsWith('/ua')) {
        navigate('/uk');
        setLanguageInitialized(true);
        return;
      }
      navigate(fullPathname);
    }

    setLanguageInitialized(true);
  }, [language, pathLanguage]);

  return <>{children}</>;
};
