import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Application } from '@widgets/cabinet/applicationCard/types';
import { applicationsAdapter } from './adapter';
import { applicationsSelectorsDraft } from './selectors';

const applicationsSlice = createSlice({
  name: 'applications',
  initialState: applicationsAdapter.getInitialState(),
  reducers: {
    upsertOne: applicationsAdapter.upsertOne,
    removeOne: applicationsAdapter.removeOne,
    removeAll: applicationsAdapter.removeAll,
    removeMany: applicationsAdapter.removeMany,
    toggleItemIsChecked: (
      state,
      { payload: { application, itemId } }: PayloadAction<{ application: Application; itemId: number }>,
    ) => {
      const selectedApplication = applicationsSelectorsDraft.selectById(state, application.id);
      const selectedApplicationItemIds = selectedApplication?.items.map((item) => item.id) ?? [];
      const index = selectedApplicationItemIds.findIndex((selectedId) => selectedId === itemId);
      if (index === -1) {
        const itemIdsToUpsert = [...selectedApplicationItemIds, itemId];
        const applicationToUpsert = {
          ...application,
          items: application.items.filter((item) => itemIdsToUpsert.includes(item.id)),
        };
        applicationsAdapter.upsertOne(state, applicationToUpsert);
      } else {
        selectedApplicationItemIds.splice(index, 1);
        if (selectedApplicationItemIds.length !== 0) {
          const applicationToUpsert = {
            ...application,
            items: application.items.filter((item) => selectedApplicationItemIds.includes(item.id)),
          };
          applicationsAdapter.upsertOne(state, applicationToUpsert);
        } else {
          applicationsAdapter.removeOne(state, application.id);
        }
      }
    },
  },
});

export const applicationsReducer = applicationsSlice.reducer;

export const applicationsActions = {
  ...applicationsSlice.actions,
};
