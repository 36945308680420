import { User } from '@shared/types/common.types';
import { CustomerProfile } from '@widgets/customer/types';

import { emptySplitApi } from '../emptySplitApi';

import { LogInResponse, RestorePasswordRequest, SignInRequest, SignInResponse, SignInVerifyRequest } from './types';

const extendedApi = emptySplitApi
  .enhanceEndpoints({
    addTagTypes: ['User', 'Applications', 'Profile'],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createMergeUsersRequest: build.mutation<{ success: boolean }, { phoneNumber: string }>({
        query: ({ phoneNumber }) => ({
          url: '/users/merge/request',
          method: 'POST',
          body: { phoneNumber },
        }),
      }),
      mergeUsers: build.mutation<LogInResponse, { code: string; phoneNumber: string }>({
        query: ({ code, phoneNumber }) => ({
          url: '/users/merge',
          method: 'POST',
          body: { code, phoneNumber },
        }),
      }),
      createUpdatePhoneRequest: build.mutation<{ requestId: string }, { phoneNumber: string }>({
        query: ({ phoneNumber }) => ({
          url: '/users/phone/update/request',
          method: 'POST',
          body: { phoneNumber },
        }),
      }),
      updatePhone: build.mutation<{ success: boolean }, { code: string; requestId: string }>({
        query: ({ code, requestId }) => ({
          url: '/users/phone/update',
          method: 'POST',
          body: { code, requestId },
        }),
        invalidatesTags: ['User'],
      }),
      me: build.query<User, void>({
        query: () => ({ url: '/users/me' }),
      }),
      getUserProfile: build.query({
        query: () => ({ url: '/users/profile' }),
        providesTags: ['User'],
      }),
      updateUserProfile: build.mutation({
        query: (body) => ({
          url: '/users/profile',
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['User'],
      }),
      signUpVolunteer: build.mutation<LogInResponse, FormData>({
        query: (body) => ({
          url: '/users/volunteer/sign-up',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['User', 'Applications'],
      }),
      signUpInNeed: build.mutation<LogInResponse, FormData>({
        query: (body) => ({
          url: '/users/in_need/sign-up',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['User', 'Applications'],
      }),
      signIn: build.mutation<SignInResponse, SignInRequest>({
        query: (body) => ({
          url: '/users/sign-in',
          method: 'POST',
          body,
        }),
      }),
      signInVerify: build.mutation<LogInResponse, SignInVerifyRequest>({
        query: (body) => ({
          url: '/users/verify',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['User', 'Applications'],
      }),
      restorePassword: build.mutation<null, RestorePasswordRequest>({
        query: (body: RestorePasswordRequest) => ({
          url: '/users/restore-password',
          method: 'POST',
          body,
        }),
      }),
      updatePassword: build.mutation<null, { phoneNumber: string; oldPassword: string; password: string }>({
        query: (body) => ({
          url: '/users/set-new-password',
          method: 'POST',
          body,
        }),
      }),
      createGuest: build.mutation<LogInResponse, void>({
        query: () => ({
          url: '/users/guests',
          method: 'POST',
        }),
        invalidatesTags: ['User'],
      }),
      logOut: build.mutation<LogInResponse, void>({
        query: () => ({
          url: '/users/logout',
          method: 'POST',
        }),
        invalidatesTags: ['User', 'Applications'],
      }),
      processPasswordProtection: build.mutation<{ token: string }, string>({
        query: (password: string) => ({
          url: '/protection',
          method: 'POST',
          body: { password },
        }),
        invalidatesTags: ['User'],
      }),
      getCustomerProfile: build.query<CustomerProfile, string>({
        query: (userId: string) => ({ url: `/users/${userId}/customer` }),
        providesTags: (_, __, userId) => [{ type: 'Profile', id: userId }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useMeQuery,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useLazyMeQuery,
  useSignUpVolunteerMutation,
  useSignUpInNeedMutation,
  useSignInMutation,
  useSignInVerifyMutation,
  useRestorePasswordMutation,
  useUpdatePasswordMutation,
  useCreateGuestMutation,
  useProcessPasswordProtectionMutation,
  useLogOutMutation,
  useCreateMergeUsersRequestMutation,
  useMergeUsersMutation,
  useGetCustomerProfileQuery,
  useCreateUpdatePhoneRequestMutation,
  useUpdatePhoneMutation,
} = extendedApi;
